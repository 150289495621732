<!-- <ion-footer> -->
<ion-grid class="ion-padding-top">
	<ion-row>
		<ion-col class="ion-justify-content-start">
			<a [href]="Author.Website" target="_blank" [title]="Author?.Name">
				<span *ngIf="FooterTitle">{{FooterTitle}}</span>
				<ion-img [src]="Author?.Logo?.Footer"></ion-img>
			</a>
		</ion-col>
		<ion-col class="ion-justify-content-end">{{Version}}</ion-col>
	</ion-row>
</ion-grid>
<!-- </ion-footer> -->