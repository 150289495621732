<ion-header>
	<ion-toolbar class="ion-padding-end">
		<!-- <ion-title>Menus</ion-title> -->

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon iconMode slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">

	<ion-grid>
		<ion-row>
			<ion-col>
				<img *ngIf="Image" [src]="Image" />
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>