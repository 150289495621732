import { Injectable } from '@angular/core';
import { ColorsUtils } from '@utils/ColorsUtils';

import {
	Chart,
	LineElement,
	BarElement,
	PointElement,
	BarController,
	BubbleController,
	LineController,
	TimeScale,
	Legend,
	Title,
	Tooltip,
	SubTitle,
	LinearScale,
	CategoryScale
} from 'chart.js';

@Injectable(
	{
		providedIn: 'root'
	})
export class ChartJSService {
	constructor() { }

	async Init() {
		Chart.register
			(
				LinearScale,
				LineElement,
				BarElement,
				PointElement,
				BarController,
				BubbleController,
				LineController,
				TimeScale,
				CategoryScale,
				Legend,
				Title,
				Tooltip,
				SubTitle
			);

		return await Promise.resolve(true);
	}


	/**
	 * 
	 * @param ctx 
	 * @param label 
	 * @param dataset 
	 * @returns 
	 */
	GetBarChart(ctx: any, label: string | any, dataset: { data: any, label: string }[] = []) {

		dataset = dataset.map((el, index) => {

			// console.log("dataset index_>", index);

			let color = ColorsUtils.CSSVariableValue('--chart-color-primary');

			switch (index) {
				case 0:
					color = ColorsUtils.CSSVariableValue('--chart-color-primary');
					break;
				case 1:
					color = ColorsUtils.CSSVariableValue('--chart-color-secondary');
					break;
				case 2:
					color = ColorsUtils.CSSVariableValue('--chart-color-others-1');
					break;
				case 3:
					color = ColorsUtils.CSSVariableValue('--chart-color-others-2');
					break;
				case 4:
					color = ColorsUtils.CSSVariableValue('--chart-color-others-3');
					break;
				default:
					color = ColorsUtils.CSSVariableValue('--chart-color-others');
					break;
			}
			return {
				data: el?.data || [],
				label: el?.label || '--',
				backgroundColor: color,
				borderColor: color,
				borderWidth: 1
			}
		});

		const chartBar = new Chart(ctx,
			{
				type: 'bar',
				data:
				{
					labels: label,
					datasets: dataset
				},
				options:
				{
					maintainAspectRatio: false,
					scales:
					{
						y: { beginAtZero: true }
					},
					animation: false,
					plugins:
					{
						legend:
						{
							position: 'bottom',
							align: 'start',
							onClick: (e) => { console.log(e); }
						}
					},
				}
			});

		return chartBar;
	}

	/**
	 * 
	 * @param ctx 
	 * @param label 
	 * @param dataset 
	 * @returns 
	 */
	GeLineChart(ctx: any, label: string | any, dataset: { data: any, label: string }[] = []) {

		dataset = dataset.map((el, index) => {

			// console.log("dataset index_>", index);

			let color = ColorsUtils.CSSVariableValue('--chart-color-primary');

			switch (index) {
				case 0:
					color = ColorsUtils.CSSVariableValue('--chart-color-primary');
					break;
				case 1:
					color = ColorsUtils.CSSVariableValue('--chart-color-secondary');
					break;
				case 2:
					color = ColorsUtils.CSSVariableValue('--chart-color-others-1');
					break;
				case 3:
					color = ColorsUtils.CSSVariableValue('--chart-color-others-2');
					break;
				case 4:
					color = ColorsUtils.CSSVariableValue('--chart-color-others-3');
					break;
				default:
					color = ColorsUtils.CSSVariableValue('--chart-color-others');
					break;
			}
			return {
				data: el?.data || [],
				label: el?.label || '--',
				backgroundColor: color,
				borderColor: color,
				borderWidth: 1
			}
		});

		const chartLine = new Chart(ctx,
			{
				type: 'line',
				data:
				{
					labels: label,
					datasets: dataset
				},
				options:
				{
					maintainAspectRatio: false,
					scales:
					{
						y: { beginAtZero: true }
					},
					animation: false,
					plugins:
					{
						legend:
						{
							position: 'bottom',
							align: 'start',
							onClick: (e) => { console.log(e); }
						}
					},
				}
			});

		return chartLine;
	}
}
