import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
	selector: 'input-browse',
	templateUrl: './input-browse.component.html',
	styleUrls: ['./input-browse.component.scss'],
})
export class InputBrowseComponent implements OnInit {

	@Input() buttonName: string = "Escolher ficheiro";
	@Input() accept: string = "image/jpg, image/jpeg, image/png";
	@Input('fileURL') FileURL: string;
	@Input('fileName') Filename: string;
	@Input('disabled') Disabled: boolean;
	@Input('template') TemplateFile: string;

	@Output() callback: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('file') File: ElementRef;

	constructor() { }

	ngOnInit() {

		console.log(this.FileURL, this.Filename, this.TemplateFile);

	}

	fileChangeEvent(ev?: any) {

		if (!ev || this.Disabled) return;

		this.FileURL = null;

		// this.Filename = this.File.nativeElement?.files[0].name || null;

		if (this.callback) this.callback.emit(this.File.nativeElement);
	}

	OnViewFile(ev: any) {


		window.open(this.FileURL, '_blank');
	}

}
