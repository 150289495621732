export class ColorsUtils {
    /**
     * Retorna cor no formato hsl a partir de uma string.
     * @param str 
     * @returns 
     */
    public static HSLColorFromString(str) {
        if (!str) return ColorsUtils.RandomHex();

        let hash = 0;

        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        return `hsl(${hash % 360}, 100%, 80%)`;
    }

    /**
     * Gera um cor Random no formato Hex #000000.
     * @param value string to trim
     * @returns 
     */
    public static RandomHex(): string {
        return `#${Math.random().toString(16).slice(2, 8).padEnd(6, '0')}`;
    }

    /**
     * Convert cor no formato RGB para Hex.
     * @param value string to trim
     * @returns 
     */
    public static ConvertRGBtoHEX(r, g, b): string {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    /**
     * Convert cor no formato hex para RGB.
     * @param hex string
     * @returns objecto { r: number, g: number, b: number }
     */
    public static ConvertHEXtoRGB(hex: string) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : null;
    }

    /**
     * 
     * @param variable 
     */
    public static CSSVariableValue(variable: string = '') {

        const value = getComputedStyle(document.documentElement).getPropertyValue(variable);

        return value || null;

    }
}