import { FormValidators } from './../../../validators/form.validators';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnInit {

	public ErrorMessagesArray:Array<{Type:string, Message:string}>;

	/**
	 * Path/URL com a localização do icon.
	 * Pode tambem ser enviada uma base64 com a source da imagem.
	 */
	@Input() control?: FormControl|UntypedFormControl|any;

	constructor()
	{
		
	}

	ngOnInit() 
	{
		this.ErrorMessagesArray = FormValidators.ErrorMessagesArray;

		// if (this.control)
		// {
		// 	console.log(this.control);
		// }
	}

}
