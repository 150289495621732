export enum EnumAppMenu {
    Initialize = "initialize",
    Error = "error",
    Login = "login",
    Recovery = "recovery",
    Dashboard = "dashboard",
    Notifications = "notifications/list",
    Settings = "settings",
    Account = "account",

    News = "news",
    NewsAdd = "news/add",
    NewsEdit = "news/edit",
    NewsView = "news/view",

    DonationsNew = "donations/new",

    Donations = "donations/list",
    DonationsView = "donations/list/view",
    DonationsEdit = "donations/list/edit",
    DonationsAdd = "donations/list/add",

    DonationsMyListView = "donations/mylist/view",
    DonationsMyListEdit = "donations/mylist/edit",
    DonationsMyListAdd = "donations/mylist/add",
    DonationsMyList = "donations/mylist",

    DonationsBookings = "donations/bookings/list",
    DonationsBookingsView = "donations/bookings/list/view",

    DonationsCategories = "donations/categories",
    DonationsCategoriesAdd = "donations/categories/add",
    DonationsCategoriesView = "donations/categories/view",
    DonationsCategoriesEdit = "donations/categories/edit",


    Donors = "donors",
    DonorsView = "donors/list/view",
    DonorsEdit = "donors/list/edit",
    DonorsAdd = "donors/list/add",

    
    DonorsPending = "donors/pending",
    DonorsPendingView = "donors/pending/view",


    Configurations = "configurations",
    ConfigurationsMenuView = "configurations/general/menu/view",
    ConfigurationsMenuEdit = "configurations/general/menu/edit",
    ConfigurationsEditionView = "configurations/general/edition/view",
    ConfigurationsEditionEdit = "configurations/general/edition/edit",
    ConfigurationsMenuAdd = "configurations/general/menu/add",
    ConfigurationsEditionAdd = "configurations/general/edition/add",
    ConfigurationsPermissionAdd = "configurations/general/permission/add",
    ConfigurationsPermissionView = "configurations/general/permission/view",
    ConfigurationsPermissionEdit = "configurations/general/permission/edit",

    ConfigurationsApplicationPeriod = "configurations/application-period",


    UsersList = "users/list",
    UsersAdd = "users/list/add",
    UsersView = "users/list/view",
    UsersEdit = "users/list/edit",


}

export enum CONFIGURATIONSTABSEGMENTSIDS {
    EDITION = 1,
    MENU = 2,
    PERMISSIONS = 3
}

export interface IAppMenu {
    Title: string,
    Url: string,
    Icon: string
}
export interface IMenusModel {

    // Children?: IMenusModel[];
    // IdParent: number;
    // IsSingleData: boolean;
    // Name: string;
    // NavigationBackgroundColor: string;
    // NavigationIcon: string;
    // NavigationPath: string;
    // NavigationPosition: number;
    // NavigationTextColor: string;
    // Parent: any;
    // Id: string;
    // RegistDate?: string;
    // RegistUser?: number;
    // Scope: any;
    Position?: number;
    // IdPlatform?: number;
    QueryParams?: any;
    // IsPublic?: boolean;


    Id: string;
    IdEntity?: string;
    IdScope?: number;
    IdPlatform: number;
    Name?: string;
    IdParent?: string;
    IsSingleData?: boolean;
    NavigationPath?: string;
    NavigationIcon?: string;
    NavigationPosition?: number;
    NavigationBackgroundColor?: string;
    NavigationTextColor?: string;
    IsTabNavigation?: boolean;
    IsPublic?: boolean;
    Scope?: IMenusScopeModel,
    Parent?: IMenusModel,
    Children?: IMenusModel[],
    CreateDate?: string;
    PermittedScopeOperationList?: number[];

}

export interface IMenusScopeModel {
    Id: number;
    IdParent: string;
    ShowOnDashboard: boolean;
    ForDataManagement: boolean;
    Name: string;
    OperationList: number[];
    CreateDate: string;
}
export interface IPlatformMenusModel {
    Menus: IMenusModel[],
    Platform: any
}

export interface IMenuTabModel extends IMenusModel {
    IdTab: number;
}

export class MenuTabModel implements IMenuTabModel {

    Id: string;
    IdPlatform: number;
    IsTabNavigation?: boolean;
    Scope?: IMenusScopeModel;
    NavigationPath?: string;
    NavigationPosition?: number;
    Name?: string;

    IdTab: number;

    constructor(data: Partial<IMenuTabModel> = {}) {

        this.Id = data?.Id || null;
        this.IdPlatform = data?.IdPlatform || null;
        this.IsTabNavigation = data?.IsTabNavigation || null;
        this.Scope = data?.Scope || null;
        this.NavigationPath = data?.NavigationPath ? ("/" + data?.NavigationPath) : '/';
        this.NavigationPosition = data?.NavigationPosition || null;
        this.Name = data?.Name || '';

        // console.log(this.NavigationPath);


        if (this.NavigationPath) {
            const statecopy = new URL(window.location.origin + this.NavigationPath.slice(0, this.NavigationPath.length));
            const queryparams = Object.fromEntries(statecopy.searchParams as any);

            this.IdTab = queryparams.id ? parseInt(queryparams.id) : null;
        }
        else
            this.IdTab = null;

    }
}