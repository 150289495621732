<ion-header>
	<ion-toolbar class="ion-padding-end">
		<!-- <ion-title>Data</ion-title> -->

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">

	<ion-grid>
		<ion-row>
			<ion-col>

				<ion-item button *ngFor="let item of Data" class="ion-padding-start ion-padding-end">
					<ion-label>{{item?.Name||'--'}}</ion-label>
					<ion-button slot="end" fill="clear" color="dark" shape="round" (click)="OnAddItem(item)">
						<ion-icon slot="icon-only" name="add-outline"></ion-icon>
					</ion-button>
				</ion-item>

				<div *ngIf="Data?.length==0" class="ion-margin-top">
					<ion-icon name="file-tray-outline"></ion-icon>
					<p>sem registos</p>
				</div>

			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>