<ion-header class="large" [translucent]="true">
	<ion-toolbar>

		<ion-buttons slot="start">

			<ion-menu-button *ngIf="!BackPath" color="dark"></ion-menu-button>
			<ion-back-button [defaultHref]="BackPath" color="dark" (click)="OnGoBack()"></ion-back-button>

			<!-- <select-edition [enabled]="IsEnabled" (edition)="OnChangeEdition($event)"></select-edition> -->
		</ion-buttons>

		<ion-grid>
			<ion-row>
				<ion-col class="ion-padding" style="max-width: calc(100% - 52px);">
					<ion-text class="title ion-justify-content-end">{{User?.Name||User?.Username||'--'}}</ion-text>
					<ion-text class="subtitle ion-justify-content-end">{{ User?.Template?.Name||Entity?.Name||
						'--'}}</ion-text>
				</ion-col>
				<ion-col style="max-width: 60px;" class="ion-justify-content-end">
					<user-avatar (click)="OnShowDefinitions($event)" [name]="User?.Name||User?.Username"></user-avatar>
				</ion-col>
			</ion-row>
		</ion-grid>

	</ion-toolbar>
</ion-header>