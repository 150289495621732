export class URLUtils 
{
    /**
     * Recebe uma string como parametro efectua trim() e remove white space duplicado.
     * @param value string to trim
     * @returns 
     */
    public static GetParam(url:string, param:string = 'token'):string
    {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);

        return params.get(param);
    }

    /**
     * remove a primeira parte encontrada
     * @param path 
     * @param char 
     * @returns 
     */
    public static RemoveFirstChar(path:string, char:string):string
    {
        console.log(path, char);
        
        path = path.charAt(0) == char ? path.replace(char, '') : path;

        return path;
    }

    /**
     * temp fix the path when the application is publish because of '/pt/name-path'
     * @param path 
     * @returns 
     */
    public static RemoveLanguageReference(path:string):string
    {
        return path.replace(/\/en\/|\/pt\//gi, '');
    }
}