<ion-grid>
    <ion-row>
        <ion-col style="display: flex;">
            <ion-input type="text" [value]="Filename||''" readonly></ion-input>
            <input #file hidden type="file" (change)="fileChangeEvent($event)" [accept]="accept" />
            <ion-button *ngIf="FileURL" fill="clear" size="small" color="medium" class="ion-margin-start"
                (click)="OnViewFile($event)">
                <ion-icon iconMode slot="icon-only" name="open-outline"></ion-icon>
            </ion-button>
        </ion-col>
        <ion-col *ngIf="!Disabled" style="
        max-width: 155px;
        text-align: right;">
            <ion-button color="medium" (click)="file?.click()">{{buttonName}}</ion-button>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="TemplateFile">
        <ion-col>
            <a class="file-template" [href]="TemplateFile" download>ficheiro template</a>
        </ion-col>
    </ion-row>
</ion-grid>
<!-- <span class="fix-filename">{{Filename||''}}</span> -->