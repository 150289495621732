import { MenuService } from '@services/menu.service';
import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
	selector: '[actionsPermissions]'
})
export class ActionsPermissionsDirective implements OnChanges {

	/**
	 * Permissão a verificar
	 */
	@Input() actionsPermissions: number = null;

	/**
	 * Passar unicamente quando existe separadores para detetar alteração e atualizar permissões
	 */
	@Input() idTab: number = null;

	/**
	 * Devolve permissões disponiveis
	 */
	@Output('callback') Callback: EventEmitter<Array<any>> = new EventEmitter();

	private El: ElementRef;

	constructor(
		private ElementRef: ElementRef,
		private Router: Router,
		private MenuService: MenuService) {

		this.El = ElementRef;

	}
	ngOnInit() {

		this.checkPermissions();

	}

	ngOnChanges(ev) {

		// console.log(ev);

		this.checkPermissions();
	}

	private checkPermissions() {

		const state = this.Router.url;

		const statecopy = new URL(window.location.origin + state.slice(0, state.length));

		const queryparams = Object.fromEntries(statecopy.searchParams as any);
		
		if (state && statecopy?.pathname) {
			const operations: number[] = this.MenuService.GetPermissionsMenuByPath(statecopy.pathname, Number(queryparams?.id||0));

			if (this.actionsPermissions && !operations.some(it => it == parseInt("" + this.actionsPermissions)))
				this.El.nativeElement.remove();

			if (!this.actionsPermissions && this.Callback) {

				this.Callback.emit(operations);

			}
		}
	}
}
