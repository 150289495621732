import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) 
{
	enableProdMode();

	if (window)
    {
        window.console.log 		= function() { };
        window.console.warn 	= function() { };
        window.console.error 	= function() { };
        window.console.info 	= function() { };
    }
}

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.log(err));
