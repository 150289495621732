import { ITeamsModel } from '@models/Competition';
import { IUserInfoModel } from '@models/User';
import { IAuthCredentials, IAuthModel, ISession } from '@models/AuthUser';
import { IAPIResponse, AbstractAPI, EnumAPIStatus, IAPIPaginationResponse } from '@api/AbstractAPI';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IFilterClassification, IFilterList } from '@models/Content';
import { IDashboardResult } from '@models/Dashboard';

@Injectable({
	providedIn: 'root'
})
export class WebService extends AbstractAPI {

	public serverSessionLossSubject: Subject<boolean>;

	public ServerSessionLoss: Observable<boolean>;

	constructor(protected HttpClient: HttpClient) {
		super(HttpClient);

		this.serverSessionLossSubject = new Subject<boolean>();

		this.ServerSessionLoss = this.serverSessionLossSubject.asObservable();
	}

	//#region AUTHENTICATION METHODS

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async Login(data: IAuthCredentials): Promise<IAPIResponse<IAuthModel>> {

		// nop futuro para multiplos projetos será necessario passar a global de modo a identificar o utilizador para o projeto
		const submit =
		{
			// global: this.Global,
			// data: {
			username: data.Username,
			password: data.Password
			// }
		};

		try { return await this.RequestPOST<IAuthModel>("Auth/Login", submit); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async RecoverPassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

		try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/RecoverPassword", data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ResetPassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

		try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/ResetPassword", data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangePersonalData(data: any): Promise<IAPIResponse<IUserInfoModel>> {

		try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/ChangeName", data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangePassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

		try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/ChangePassword", data)); }
		catch (error) { return error; }
	}


	/**
	 * 
	 * @returns 
	 */
	async Logout(): Promise<IAPIResponse<boolean>> {

		try { return this.handleAuthResponse(await this.RequestGET<boolean>("Auth/Logout")); }
		catch (error) { return error; }
	}

	//#endregion

	//#region EDITION METHODS

	/**
	 * 
	 * @returns 
	 */
	async GetBaseEditionList(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>("Common/CalendarYear/GetBaseList")); }
		catch (error) { return error; }
	}
	/**
	 * 
	 * @param filter 
	 * @returns 
	 */
	async GetEditionList(filter?: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>("Race/Edition/GetList", filter)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetEditionById(id: number): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Race/Edition/Get?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param form 
	 * @returns 
	 */
	async SaveEdition(form): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Race/Edition/Save`, form)); }
		catch (error) { return error; }
	}

	async DeleteEdition(id: number): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Race/Edition/Delete?id=${id}`)); }
		catch (error) { return error; }
	}
	//#endregion

	//#region COMMON METHODS

	/**
	 * 
	 * @param formData 
	 * @returns 
	 */
	async UploadAndGetUrl(formData?: FormData): Promise<IAPIResponse<string>> {

		try { return this.handleAuthResponse(await this.RequestPOSTFormData<string>("Generic/File/UploadAndGetUrl", formData)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetGenreList(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/Gender/GetList`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetEntityList(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Common/Entity/GetList`)); }
		catch (error) { return error; }
	}
	/**
	 * 
	 * @param idCalendarYear 
	 * @returns 
	 */
	async GetDashboardData(idCalendarYear?: string): Promise<IAPIResponse<IDashboardResult>> {

		// ?idCalendarYear=${idCalendarYear}
		try { return this.handleAuthResponse(await this.RequestGET<IDashboardResult>(`Dashboard/Get`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetPlatformList(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/GetPlatformList`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetAvailableLanguages(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/Language/GetList`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetDonorSubmissionConfiguration(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Common/GetDonorSubmissionConfiguration`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async SaveDonorSubmissionConfiguration(data): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Common/SaveDonorSubmissionConfiguration`, data)); }
		catch (error) { return error; }
	}
	//#endregion

	//#region MENUS

	/**
	 * devolve a lista de menus que o utilizador tem acesso
	 * @returns 
	 */
	async GetNavigationList(): Promise<IAPIResponse<any[]>> {

		try { return this.handleAuthResponse(await this.RequestGET<any[]>("Menu/GetNavigationList")); }
		catch (error) { return error; }
	}

	/**
	 * Devolve todas as permissoes criadas
	 * @returns 
	 */
	async GetMenuListScopes(): Promise<IAPIResponse<any>> {

		// const params = `idScope=${idScope}&idMenu=${idMenu}`;

		try { return this.handleAuthResponse(await this.RequestGET<any>("Menu/GetScopeList")); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param idScope 
	 * @param idMenu 
	 * @returns 
	 */
	async GetOperationList(idScope?: string, idMenu?: string): Promise<IAPIResponse<any>> {

		// const params = `idScope=${idScope}&idMenu=${idMenu}`;

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/GetOperationList?idScope=${idScope || ''}&idMenu=${idMenu || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetMenuTabList(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>("Menu/GetTabList")); }
		catch (error) { return error; }
	}
	/**
	 * devolve lista de menus de todo projeto para configuração
	 * @returns 
	 */
	async GetMenuList(idPlatform?: number): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/GetList?idPlatform=${idPlatform || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * devolve menu para configuração
	 * @returns 
	 */
	async GetMenu(id: number): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/Get?id=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async SaveMenu(data): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Menu/Save`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async DeleteMenu(id: number): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Menu/Delete?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param menus 
	 * @returns 
	 */
	async ChangePositionMenu(menus: { Id: string, Position: number, IdParent?: string }[]): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Menu/ChangePosition`, menus)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param idParent 
	 * @param idScope 
	 * @returns 
	 */
	async GetSimpleMenuListPermissions(idParent?: string, idScope?: number): Promise<IAPIResponse<any>> {

		const params: string = `idParent=${idParent || ''}&idScope=${idScope || ''}&isPublic=${''}&isTabNavigation=${''}`;

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/GetSimpleList?${params}`)); }
		catch (error) { return error; }
	}

	//#endregion

	//#region CONTENT METHODS
	/**
	 * @param filters
	 * @returns 
	 */
	async GetContentList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Content/GetList?PageNumber=${filters?.PageNumber}&PageRows=${filters?.PageRows}&Text=${filters.Text}`)); }
		catch (error) { return error; }
	}

	/**
	 * @param id
	 * @returns 
	 */
	async DeleteContentItem(id: number): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Content/Delete?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetContentDetails(id: number, idEdition: number = 0): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Content/Get?id=${id}&idEdition=${idEdition || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * @param data
	 * @returns 
	 */
	async SaveContentData(data: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Content/Save`, data)); }
		catch (error) { return error; }
	}
	//#endregion

	//#region NOTIFICATIONS

	async SendUserNotification(data: { observation: string, idPerson: string }): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Race/Edition/SendUserNotification`, data)); }
		catch (error) { return error; }
	}

	//#endregion

	//#region USERS

	/**
	 * 
	 * @returns 
	 */
	async GetUserInfo(): Promise<IAPIResponse<IUserInfoModel>> {

		try { return this.handleAuthResponse(await this.RequestGET<IUserInfoModel>("User/GetInfo")); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetUsersList(filters: IFilterList): Promise<IAPIResponse<any[]>> {

		const params = `
					PageNumber=${filters?.PageNumber || ''}&
					PageRows=${filters?.PageRows || ''}&
					Text=${filters?.Text || ''}&
					OrderDescending=${filters?.OrderDescending ?? true}`;

		try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`User/List?${params}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetUsersById(id: number): Promise<IAPIResponse<any[]>> {

		try { return this.handleAuthResponse(await this.RequestGET<any[]>(`User/Get?id=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data
	 * @returns 
	 */
	async SaveUser(data: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`User/Save`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async DeleteUser(id: string): Promise<IAPIResponse<any[]>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`User/Delete?id=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @returns 
	 */
	async GetRoleUsersList(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`User/Role/GetList`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetRoleUserPermissionTemplate(id: string = null): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`User/Permissions/GetTemplate?id=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param idUserRole 
	 * @returns 
	 */
	async GetRoleUserPermissionListTemplate(idUserRole: number = null): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`User/Permissions/GetTemplateList?idUserRole=${idUserRole || ''}`)); }
		catch (error) { return error; }
	}


	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async SaveRoleUserPermissionTemplate(data: any): Promise<IAPIResponse<any>> {

		const params = data;

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Permissions/SaveTemplate`, params)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async DeleteRoleUserPermissionTemplate(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Permissions/DeleteTemplate?id=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async UserGenerateNewPassword(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Credentials/GenerateNewPassword/${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async UserSendEmailToDefinePassword(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Credentials/SendEmailToDefinePassword/${id}`)); }
		catch (error) { return error; }
	}
	//#endregion

	//#region DONATIONS
	/**
	 * 
	 * @param  
	 * @returns 
	 */
	async GetArticlesCategories(): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Article/Category/GetList`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangeArticleStatus(data: { idArticle: string, idStatus: number, observations: string }): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/ChangeStatus`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetArticle(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Article/Get?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetArticleHistoric(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Article/GetStatusList?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param filters
	 * @returns 
	 */
	async GetArticleList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

		const params = {
			searchData: {
				pageNumber: filters?.PageNumber || 1,
				pageRows: filters?.PageRows || 25,
				orderDescending: filters?.OrderDescending || true,
				text: filters?.Text || "",

			},
			statusList: filters?.IdStatus || [],
			isPublic: filters?.IsPublic || "",

		};

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/GetList`, params)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async SaveArticle(data: FormData): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOSTFormData<any>(`Article/Save`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangePublicStatusArticle(data: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/ChangePublicStatus`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangeBlockedStatusArticle(data: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/ChangeBlockedStatus`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param idArticle
	 * @param data 
	 * @returns 
	 */
	async SaveArticleFiles(idArticle: string, data: any[]): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/File/SaveList?idArticle=${idArticle}`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param idFile 
	 * @returns 
	 */
	async DeleteArticleFile(idFile: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/File/Delete`, JSON.stringify(idFile))); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param file 
	 * @returns 
	 */
	async ArticleFileDefineMain(file: { idArticle: string, idArticleFile: string, isMain: boolean }): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/File/DefineMain`, file)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async FinishArticle(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/Finish/${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async DeleteArticle(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/Delete/${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetDonation(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Donation/Get?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetDonationStatusList(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Donation/GetStatusList?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param filters 
	 * @param status 
	 * @returns 
	 */
	async GetDonationList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

		const params = {
			searchData: {
				pageNumber: filters?.PageNumber || 1,
				pageRows: filters?.PageRows || 25,
				orderDescending: filters?.OrderDescending || true,
				text: filters?.Text || "",
			},
			statusList: filters?.IdStatus || [],
			idArticle: filters?.IdArticle || null

		};

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donation/GetList`, params)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangeStatusDonation(data: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donation/ChangeStatus`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param idDonation 
	 * @returns 
	 */
	async GetArticleDeliveryInfo(idDonation: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Donation/GetArticleDeliveryInfo?idDonation=${idDonation}`)); }
		catch (error) { return error; }
	}


	/**
	 * 
	 * @param idDonation 
	 * @returns 
	 */
	async GetDeliveryInfo(idDonation: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Donation/GetDeliveryInfo/${idDonation}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param filters 
	 * @returns 
	 */
	async GetArticleCategoryList(filters: any): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Article/Category/GetList`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetArticleCategory(id: string): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Article/Category/Get?id=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async SaveArticleCategory(data: any): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/Category/Save`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async DeleteArticleCategory(id: string): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Article/Category/Delete/${id || ''}`)); }
		catch (error) { return error; }
	}
	//#endregion

	//#region DONOR
	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async GetDonor(id: string): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Donor/Get?id=${id}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id
	 * @returns 
	 */
	async GetDonorHistoric(id: string): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestGET<any>(`Donor/GetStatusList?idDonor=${id || ''}`)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	async ChangeDonorStatus(data: any): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donor/ChangeStatus`, data)); }
		catch (error) { return error; }
	}



	/**
	 * 
	 * @returns 
	 */
	async GetDonorList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

		const params = {
			searchData: {
				pageNumber: filters?.PageNumber || 1,
				pageRows: filters?.PageRows || 25,
				orderDescending: filters?.OrderDescending || true,
				text: filters?.Text || "",

			},
			statusList: filters.IdStatus || [],

		};

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donor/GetList`, params)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param filters 
	 * @returns 
	 */
	async GetDonorPendingConfirmationList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

		const params = {
			searchData: {
				pageNumber: filters?.PageNumber || 1,
				pageRows: filters?.PageRows || 25,
				orderDescending: filters?.OrderDescending || true,
				text: filters?.Text || "",

			}

		};

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donor/PendingConfirmation/GetList`, params)); }
		catch (error) { return error; }
	}

	/**
	 * Salvar Doador
	 * @param data 
	 * @returns 
	 */
	async SaveDonor(data: any): Promise<IAPIResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donor/Save`, data)); }
		catch (error) { return error; }
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	async ResendValidationCode(id: string): Promise<IAPIPaginationResponse<any>> {

		try { return this.handleAuthResponse(await this.RequestPOST<any>(`Donor/ResendValidationCode/${id}`)); }
		catch (error) { return error; }
	}
	//#endregion




	/**
	 * UTILIZAR APENAS EM METODOS SEGUROS
	 * @param response 
	 * @returns 
	 */
	private async handleAuthResponse<T>(response: IAPIResponse<T>) {

		if (!response) return;

		console.log("handler -> ", response.Status);

		if (!response.IsSuccessStatus
			&& (response.Status == EnumAPIStatus.InvalidAccessToken
				// 
				// || response.Status == EnumAPIStatus.Unauthorized
			)) {

			this.serverSessionLossSubject.next(true);
		}

		return response;
	}
}
