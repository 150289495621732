import { Component, OnInit, ChangeDetectionStrategy, ContentChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component(
{
	selector: 'password-toggle',
	templateUrl: './password-toggle.component.html',
	styleUrls: ['./password-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordToggleComponent implements OnInit 
{
	@ContentChild(IonInput) input: IonInput;

	showPassword = false;

	constructor() { }

	ngOnInit() { }

	toggleShow() 
	{
		this.showPassword = !this.showPassword;

		if (this.input)
			this.input.type = this.showPassword ? 'text' : 'password';
	}

}
