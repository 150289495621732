<ion-header [translucent]="true">
	<ion-toolbar class="ion-padding-end">

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding" [fullscreen]="true">
	<div id="content" class="ion-padding">

		<div class="overlay" *ngIf="OverlayQRCode">
			<div class="overlay-element top-left"></div>
			<div class="overlay-element top-right"></div>
			<div class="overlay-element bottom-left"></div>
			<div class="overlay-element bottom-right"></div>
		</div>

		<!-- Shows our camera stream -->
		<video #video width="100%"></video>
		<!-- Used to render the camera stream images -->
		<canvas #canvas hidden></canvas>

		<div class="ui" *ngIf="EnableUI">
			<ion-button title="Tirar fotografia" size="large" shape="round" color="light" (click)="TakePhoto($event)">
				<ion-icon slot="icon-only" name="ellipse-outline"></ion-icon>
			</ion-button>
		</div>
	</div>
</ion-content>