import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

import jsQR from 'jsqr';


@Component({
	selector: 'app-camera',
	templateUrl: './camera.component.html',
	styleUrls: ['./camera.component.scss'],
})
export class CameraModalComponent implements OnInit, OnDestroy {

	@ViewChild('video', { static: false }) video: ElementRef;
	@ViewChild('canvas', { static: false }) canvas: ElementRef;

	public OverlayQRCode: boolean = false;
	public EnableUI: boolean = false;

	private canvasElement: any;
	private videoElement: any;
	private canvasContext: any;
	private loading: HTMLIonLoadingElement = null;

	// scanActive = false;
	// scanResult = null;

	constructor(
		private LoadingController: LoadingController,
		private NavParams: NavParams,
		private ModalController: ModalController,
		private Platform: Platform
	) {

		this.OverlayQRCode = this.NavParams.get('overlayQRcode') || false;
		this.EnableUI = this.NavParams.get('enableUI') || false;

		const isInStandaloneMode = () =>
			'standalone' in window.navigator && window.navigator['standalone'];
		if (this.Platform.is('ios') && isInStandaloneMode()) {
			console.log('I am a an iOS PWA!');
			// E.g. hide the scan functionality!
		}
	}

	ngOnInit() { }

	async ngOnDestroy() {

		this.stopCameraStreamTracks();
	}

	async ngAfterViewInit() {

		this.canvasElement = this.canvas.nativeElement;
		this.canvasContext = this.canvasElement.getContext('2d');
		this.videoElement = this.video.nativeElement;

		this.initCameraStream();
	}

	private stopCameraStreamTracks() {

		if (this.videoElement && this.videoElement?.srcObject) {
			this.videoElement.srcObject.getTracks().forEach((track) => {
				if (track.readyState == 'live' && track.kind === 'video') {

					console.log(track);

					track.stop();

					this.videoElement.srcObject.removeTrack(track);
				}
			});
		}

		// this.scanActive = false;
		// this.scanResult = null;
	}

	async initCameraStream() {

		this.loading = await this.LoadingController.create({
			cssClass: "ion-loading-default",
			// message: 'Aguarde, por favor...',
			spinner: "lines",
			mode: "ios"
		});

		await this.loading.present();

		// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
		// Not working on iOS standalone mode!
		const stream = await navigator.mediaDevices.getUserMedia({
			audio: false,
			video: this.EnableUI ? {
				facingMode: 'environment',
				// width: { min: 1024, ideal: 1280, max: 1920 },
				// height: { min: 576, ideal: 720, max: 1080 },
				// // width: { min: 1280, ideal: 1920, max: 3840 },
				// // height: { min: 720, ideal: 1080, max: 2160 },
				width: { min: 1280, ideal: 1920, max: 1920 },
				height: { min: 720, ideal: 1080, max: 1080 },
				// frameRate: { ideal: 10, max: 15 }
			} : {
				facingMode: 'environment',
				width: { min: 1024, ideal: 1280, max: 1920 },
				height: { min: 576, ideal: 720, max: 1080 },
			}
		}).catch(async (err) => {
			console.log(err);

			this.stopCameraStreamTracks();

			if (this.loading)
				await this.loading.dismiss();

			const modal = await this.ModalController.getTop();
			if (modal) modal.dismiss(null, EnumGenericRoleActionAlerts.Error);
		});

		console.log(stream);

		this.videoElement.srcObject = stream;

		if (!stream) return;

		// Required for Safari
		this.videoElement.setAttribute('playsinline', true);


		this.videoElement.play();

		requestAnimationFrame(this.scan.bind(this));
	}

	private async scan() {

		console.log(this.videoElement.readyState);


		if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {

			if (this.loading) {

				await this.loading.dismiss();

				this.loading = null;

				// this.scanActive = true;
			}

			this.canvasElement.height = this.videoElement.videoHeight;
			this.canvasElement.width = this.videoElement.videoWidth;

			if (!this.OverlayQRCode || this.canvasElement.width == 0) return

			this.canvasContext.drawImage(
				this.videoElement,
				0,
				0,
				this.canvasElement.width,
				this.canvasElement.height
			);

			const imageData = this.canvasContext.getImageData(
				0,
				0,
				this.canvasElement.width,
				this.canvasElement.height
			);

			// console.log(imageData);


			const code = jsQR(imageData.data, imageData.width, imageData.height, {
				inversionAttempts: 'dontInvert'
			});

			if (code?.data) {

				this.canvasElement.toBlob((blob) => {

					const blobUrl = URL.createObjectURL(blob);

					this.stopCameraStreamTracks();

					this.ModalController.dismiss({ text: code?.data, blob: blobUrl }, EnumGenericRoleActionAlerts.Confirm);


				}, "image/jpeg", 0.90);


			} else {
				requestAnimationFrame(this.scan.bind(this));

			}
		} else {
			requestAnimationFrame(this.scan.bind(this));
		}
	}

	public async TakePhoto(ev?: any) {

		if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
			if (this.loading) {
				await this.loading.dismiss();
				this.loading = null;
				// this.scanActive = true;
			}

			this.canvasElement.height = this.videoElement.videoHeight;
			this.canvasElement.width = this.videoElement.videoWidth;

			this.canvasContext.drawImage(
				this.videoElement,
				0,
				0,
				this.canvasElement.width,
				this.canvasElement.height
			);

			// const imageDataBlob = 
			this.canvasElement.toBlob(
				(blob) => {

					const blobUrl = URL.createObjectURL(blob);

					this.stopCameraStreamTracks();

					this.ModalController.dismiss({ text: null, blob: blobUrl }, EnumGenericRoleActionAlerts.Confirm);

				},
				"image/jpeg", 0.90,
			)

		}
	}

	Close() {

		this.stopCameraStreamTracks();
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

}
