import { ModalController } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PresentImageModalComponent } from '@modals/present-image/present-image.component';

@Component({
	selector: 'card-picture',
	templateUrl: './picture.component.html',
	styleUrls: ['./picture.component.scss'],
})
export class PictureCardComponent implements OnInit {

	@Input('name') Name: string = '';
	@Input('enableMain') EnableMain: boolean = true;
	@Input('main') IsMain: boolean = false;
	@Input('enabled') IsEnabled: boolean = false;
	@Input('url') Url: string = 'https://ionicframework.com/docs/img/demos/card-media.png';
	@Output('delete') onDelete: EventEmitter<any> = new EventEmitter<any>();
	@Output('setMain') onSetMain: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private ModalController: ModalController
	) { }

	ngOnInit() { }

	OnDelete(ev?: any) {
		ev?.preventDefault();
		ev?.stopPropagation();

		this.onDelete.emit(this.Name)
	}

	OnSetMain(ev?: any) {
		ev?.preventDefault();
		ev?.stopPropagation();
		
		this.onSetMain.emit(this.Name)

	}

	async OnPresentImage(ev: any) {

		ev?.preventDefault();
		ev?.stopPropagation();

		const modal = await this.ModalController.create({
			component: PresentImageModalComponent,
			componentProps: {
				image: this.Url
			},
			cssClass: 'default full'
		});

		modal.present();

	}

}
