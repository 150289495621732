
import { EnumAppMenu } from '@models/AppMenus';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SessionService } from '@services/session.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { ProjectService } from '@services/project.service';

@Component({
	selector: 'side-menu-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class SideMenuHeaderComponent implements OnInit, OnDestroy {

	User: any;

	public ProjectLogo: string = "";

	private sub: Subscription;

	constructor(private SessionService: SessionService,
		private ProjectService: ProjectService,
		private NavController: NavController) { }

	ngOnInit() {

		this.sub = this.SessionService.Session.subscribe(session => {
			this.User = session?.UserData;


			// console.log(this.User);


			this.ProjectLogo = this.ProjectService.GetSettings()?.Project?.Logos?.Header || '';
		})

	}

	ngOnDestroy(): void {
		if (this.sub)
			this.sub.unsubscribe();
	}

	OnGoNotifications() {
		this.NavController.navigateForward([EnumAppMenu.Notifications])
	}

}
