export class HTMLUtils 
{

    public static EscapeHTMLSpecialChars(html:string)
    {
        html.replace(/[&<>"']/g, (m) => ({ '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;' }[m]));

        return html;
    }

    public static RemoveHTMLLinkStyleSheetTags(html:string)
    {
        const docHTML = document.createElement('html');

        docHTML.innerHTML = html;

        const links = docHTML.querySelectorAll('[rel="stylesheet"]');

        let i = links.length;

        while (i--) 
        {
            links[i].parentNode.removeChild(links[i]);
        }

        return docHTML.innerHTML;
    }

    public static RemoveHTMLStyleTags(html:string)
    {
        const docHTML = document.createElement('html');

        docHTML.innerHTML = html;

        const styles = docHTML.getElementsByTagName('style');
        
        let i = styles.length;

        while (i--) 
        {
            styles[i].parentNode.removeChild(styles[i]);
        }

        return docHTML.innerHTML;
    }

    public static RemoveHTMLScriptTags(html:string)
    {
        const docHTML = document.createElement('html');

        docHTML.innerHTML = html;

        const scripts = docHTML.getElementsByTagName('script');
        
        let i = scripts.length;

        while (i--) 
        {
            scripts[i].parentNode.removeChild(scripts[i]);
        }

        return docHTML.innerHTML;
    }

    public static RemoveHTMLComments(html:string):string
    {
        if (html) html = html.replace(/<\!--.*?-->/g, "");

        return html;
    }

    public static TrimHTML(html:string):string
    {
        if (html) 
        {
            html = html.replace(/\s+/g, ' ');
            html = html.trim();
            html = html.replace(new RegExp(">[\t\s ]*<", "g"), "><");
        }

        return html;
    }
}

