export class DateUtils {


    /**
     * 
     * @param value Date ISOstring
     * @returns 
     */
    public static FormatDate(value: string) {

        if (!value) return null;

        const date = new Date(value);
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    }

    /**
     * 
     * @param value Date ISOstring
     * @returns 
     */
    public static FormatHours(value: string) {

        if (!value) return null;

        const date = new Date(value);

        return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);

    }
}