import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { AlertController, NavController } from '@ionic/angular';
import { EnumStorageKeys } from './storage.service';
import { StorageService } from '@services/storage.service';
import { WebService } from './web.service';
import { ISession, Session } from '@models/AuthUser';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnumAppMenu } from '@models/AppMenus';

@Injectable({
	providedIn: 'root'
})
export class SessionService {

	/**
	 * 
	 */
	public Session: Observable<ISession>;

	/**
	 * 
	 */
	private sessionBehaviorSubject: BehaviorSubject<ISession>;

	/**
	 * 
	 */
	private session: Session;

	/**
	 * 
	  */
	public sessionLossEmited: boolean;

	constructor(private WebService: WebService,
		private AlertController: AlertController,
		private NavController: NavController,
		private StorageService: StorageService) {

		this.sessionBehaviorSubject = new BehaviorSubject<ISession>(this.session);

		this.Session = this.sessionBehaviorSubject.asObservable();

		this.Session.subscribe(session => {

			this.session = new Session(session);

			this.WebService.SetAuthorization(this.session?.AccessToken);
			this.WebService.SetEntityId(this.session?.Entity?.Id);
		});

		this.WebService.ServerSessionLoss.subscribe(async sessionLost => {

			if (sessionLost) {
				await this.notifySessionLoss();
			}

		});

	}

	/**
	 * 
	 * @returns 
	 */
	async Init() {

		const session = await this.StorageService.Get(EnumStorageKeys.Session);

		this.session = new Session(session);

		this.sessionBehaviorSubject.next(this.session);

		return this.session;
	}

	/**
	 * 
	 * @returns 
	 */
	public HasSession(): boolean {

		// 
		if (this.session && this.session?.AccessToken && this.session.IsValid())
			return true;
		else {
			this.WebService.serverSessionLossSubject.next(true);
			return false;
		}
	}

	/**
	 * 
	 * @param session 
	 */
	public SaveSession(session: ISession) {

		this.StorageService.Save(EnumStorageKeys.Session, session);

		if (session?.AccessToken)
			this.sessionLossEmited = false;

		this.sessionBehaviorSubject.next(session);
	}

	/**
	 * 
	 * @returns 
	 */
	public Get() {
		return this.session;
	}

	/**
	 * 
	 * @returns 
	 */
	private async notifySessionLoss() {

		if (!this.session?.AccessToken || this.sessionLossEmited) return;

		this.sessionLossEmited = true;

		this.SaveSession(null);

		const alert = await this.AlertController.create(
			{
				id: "session-loss-alert",
				header: 'Aviso de Sessão',
				message: 'A sua sessão terminou ou está inválida. Faça login novamente para aceder às funcionalidades da aplicação.',
				// mode: "ios",
				cssClass: 'default',
				backdropDismiss: false,
				buttons:
					[
						{
							text: 'continuar',
							role: EnumGenericRoleActionAlerts.Confirm,
							handler: () => {


							}
						}
					]
			});

		await alert.present();

		this.NavController.navigateRoot([EnumAppMenu.Login], { animated: true });

		return alert;
	}
}