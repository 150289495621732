import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultTextOrArrayValue, DefaultTextOrValue, DateFromNow, SanitizeHTML } from './generic.pipe';

const importedPipes = 
[
	DefaultTextOrArrayValue,
    DefaultTextOrValue,
	SanitizeHTML,
	DateFromNow
];

@NgModule(
{
	imports:
	[
		CommonModule
	],
	declarations: importedPipes,
	exports: importedPipes
})
export class PipesModule { }
