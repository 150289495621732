import { AbstractControl, ValidatorFn, ValidationErrors, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export class FormValidators
{
    /**
     * RegExp pattern para validação de endereços web.
     * Aceita endereços http, ftp, conjunto de minios mais habituais e dominios de 2 letras.
     */
    static URL_REGEXP = /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu||gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

    /**
     * RegExp pattern para validação de endereços de email.
     * Aceita os principais patters de email < x@x.x >.
     */
	//REGEXP DE EMAIL DEPRECATED - Estava a dar erro em dispositivos mais antigos < Android 6.
    //static EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	static EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

    /**
     * RegExp pattern para validação de números de telemóvel.
     */
    static PHONE_REGEXP = /^((?:\+|00|011){0,1}351){0,1}(9[1236]\d{7}|2\d{8})$/;

    /**
     * RegExp pattern para validação de numbers.
     */
    static NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;

    /**
     * RegExp para validação de códigos postais < 0000-000 >.
     */
    static ZIP_REGEXP = /[1-9][0-9]{3}-[0-9]{3}/;

    /**
     * IBAN Codes
     */
	static IBAN_CODES = 
	{
		AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, CH: 21, CR: 21, CY: 28, CZ: 24,
		DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, LC: 30, FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28,
		HR: 21, HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21,
		MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
		RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
	};

    //Indique um ou mais CAE do seu estabelecimento separados por espaço.<br>Apenas serão elegíveis estabelecimentos com CAE’s de acordo com o Anexo I das Normas de Participação.

    /**
     * Objecto de tipos e respectivas mensagens de erro associadas ao form validators.
     * A mensagem de erro e uma resource que deve ser adicionada ao ficheiro de resources.
     */
	static ErrorMessages = 
	{
        required:                   	{ 'required':                   'Verifique o preenchimento dos campos obrigatórios.' },
        requiredConcent:                { 'requiredConcent':            'Confirme a aceitação das condições de adesão.' },
        invalidEmail:               	{ 'invalidEmail':               'Insira um endereço de e-mail válido.' },
        invalidUrl:                 	{ 'invalidUrl':                 'Endereço WEB inválido.' },
        invalidPasswordStrength:    	{ 'invalidPasswordStrength':    'Melhore a segurança da sua palavra-passe. Inclua números, letras e/ou caracteres especiais até que a sua palavra-passe seja considerada segura.' },
        invalidPasswordMatch:       	{ 'invalidPasswordMatch':       'As palavras-passe são diferentes.' }, 
        invalidNumber:              	{ 'invalidNumber':              'Insira um número válido.' },
        invalidTelephone:           	{ 'invalidTelephone':           'Insira um contato telefónico válido.' },
        invalidZipCode:             	{ 'invalidZipCode':             'Insira um código postal no formato: 0000-000.' }, 
        invalidNIF:                 	{ 'invalidnif':                 'Insira um número nif válido.' },
		invalidIBAN:                	{ 'invalidiban':                'Insira um número IBAN válido.' },
        invalidminlength:           	{ 'invalidMinLength':           'Verifique a quantidade mínima de caracteres necessária.' },
        invalidmaxlength:           	{ 'invalidMaxLength':           'Verifique a quantidade máxima de caracteres necessária.' },
        invalidFileType:                { 'invalidFileType':            'O ficheiro indicado não é um formato válido. Formatos válidos: #filetypes#' },
        invalidMaxFileSize:             { 'invalidMaxFileSize':         'O ficheiro indicado excede o tamanho permitido de #maxfilesize#' },
        invalidMinFileSize:             { 'invalidMinFileSize':         'O ficheiro indicado possui um tamanho inferior a #minfilesize#' },
		invalidMandatoryAttachments:    { 'invalidMerchantDocs':        'Documentos inválidos ou em falta.' },
    };

    static ErrorMessagesArray = 
	[
		{ Type: 'required', 						Resource: 'FORM_VALIDATOR_MANDATORY_FIELD_ERROR' 				    , Message: 'campo de preenchimento obrigatório.'        },
		{ Type: 'minlength', 					    Resource: 'FORM_VALIDATOR_MIN_LENGTH_ERROR' 						, Message: 'campo com valor mínimo de carateres.'       },
		{ Type: 'maxlength', 					    Resource: 'FORM_VALIDATOR_MAX_LENGTH_ERROR' 						, Message: 'campo com valor máximo de carateres.'       },
		{ Type: 'invalidEmail', 				    Resource: 'FORM_VALIDATOR_EMAIL_ERROR' 								, Message: 'insira um endereço de e-mail válido.'},
		{ Type: 'usernameTaken', 				    Resource: 'FORM_VALIDATOR_USERNAME_TAKEN_ERROR' 					, Message: 'username já está em uso.'},
		{ Type: 'invalidUsername', 			        Resource: 'FORM_VALIDATOR_INVALID_USERNAME_ERROR' 				    , Message: 'username não é válido.'},
		{ Type: 'invalidPassword', 			        Resource: 'FORM_VALIDATOR_INVALID_PASSWORD_ERROR' 				    , Message: 'palavra-passe não é válida.'},
		{ Type: 'invalidPasswordMatch',		        Resource: 'FORM_VALIDATOR_PASSWORD_MATCH_INVALID_ERROR' 		    , Message: 'as palavras-passe são diferentes.'},
		{ Type: 'invalidPasswordStrength',	        Resource: 'FORM_VALIDATOR_PASSWORD_STRENGTH_INVALID_ERROR' 	        , Message: 'melhore a segurança da sua palavra-passe. Inclua números, letras e/ou caracteres especiais até que a sua palavra-passe seja considerada segura.'},
		{ Type: 'invalidTelephone',			        Resource: 'FORM_VALIDATOR_PHONE_INVALID_ERROR' 					    , Message: 'contato telefónico não é válido.'},
		{ Type: 'invalidDate',					    Resource: 'FORM_VALIDATOR_DATE_INVALID_ERROR' 					    , Message: 'data não está válida.'},
		{ Type: 'invalidUrl',					    Resource: 'FORM_VALIDATOR_URL_INVALID_ERROR' 						, Message: 'endereço WEB inválido.'},
		{ Type: 'invalidNumber',				    Resource: 'FORM_VALIDATOR_URL_INVALID_ERROR' 						, Message: 'número não está válido.'},
		{ Type: 'invalidFirstLastName',		        Resource: 'FORM_VALIDATOR_FIRST_LAST_NAME_INVALID_ERROR' 		    , Message: ''},
		{ Type: 'invalidZipCode',				    Resource: 'FORM_VALIDATOR_ZIPCODE_INVALID_ERROR' 				    , Message: ' código postal no formato: 0000-000.'},
		{ Type: 'invalidGeoJSON',				    Resource: 'FORM_VALIDATOR_GEOJSON_INVALID_ERROR' 				    , Message: ''},
		{ Type: 'uniqueValue',					    Resource: 'FORM_VALIDATOR_UNIQUE_VALUE_INVALID_ERROR' 			    , Message: ''},
		{ Type: 'invalidnif',				        Resource: 'FORM_VALIDATOR_NIF_INVALID_ERROR' 				        , Message: 'NIF não é válido'},
		{ Type: 'invalidiban',					    Resource: 'FORM_VALIDATOR_IBAN_VALUE_INVALID_ERROR' 			    , Message: 'IBN não é válido'},
	];



    private static isEmptyInputValue(value: any): boolean 
    {
        return value == null || value.length === 0 || value == -1;
    }

    private static isTrueInputValue(value: any): boolean 
    {
        return value != true;
    }

    private static hasValidLength(value: any): boolean 
    {
        // non-strict comparison is intentional, to check for both `null` and `undefined` values
        return value != null && typeof value.length === 'number';
    }

    public static HelperGetPasswordStrength(password:string)
	{
		var score:number = 0;
		
		if (!password) return score;
	
		var letters = new Object();

		for (let i = 0; i < password.length; i++)
		{
			letters[password[i]] = (letters[password[i]] || 0) + 1;

			score += 5.0 / letters[password[i]];
		}

		var variations = 
		{
			digits: /\d/.test(password),
			lower: /[a-z]/.test(password),
			upper: /[A-Z]/.test(password),
			nonWords: /\W/.test(password),
        }

		let variationCount = 0;

		for (var check in variations)
		{
			variationCount += (variations[check] == true) ? 1 : 0;
		}

		score += (variationCount - 1) * 10;

		return (score > 100) ? 100 : score;
	}

	private static mod97(digital: number | string)
	{
		digital = digital.toString();

		let checksum: number | string = digital.slice(0, 2);
		let fragment = '';

		for (let offset = 2; offset < digital.length; offset += 7) 
		{
			fragment = checksum + digital.substring(offset, offset + 7);
			checksum = parseInt(fragment, 10) % 97;
		}

		return checksum;
	}

    private static getExtension(filename: string): null | string 
    {
        if (filename.indexOf('.') === -1) 
        {
            return null;
        }

        return filename.split('.').pop().toLocaleLowerCase();
    }

    private static fileValidation(validatorFn: (File) => null|object): ValidatorFn 
    {
        return (formControl: AbstractControl) => 
        {
            if (!formControl.value) 
            {
                return null;
            }
    
            const files: File[] = [];

            const isMultiple = Array.isArray(formControl.value);

            isMultiple
            ? formControl.value.forEach((file: File) => files.push(file))
            : files.push(formControl.value);
    
            for (const file of files) 
            {
                return validatorFn(file);
            }
    
            return null;
        };
    }
    
    /**
     * Pesquisa recursivamente um FormGroup | FormArray retornando o conjunto de objectos de erros de vários FormControl que possam existir.
     * @param formToInvestigate FormGroup | FormArray
     */
    static GetInvalidControls(formToInvestigate:UntypedFormGroup | UntypedFormArray):Object 
    {
        let errors:{} = {};

        const recursiveFunc = (form:UntypedFormGroup|UntypedFormArray) => 
        {
            Object.keys(form.controls).forEach(key => 
            {
                const control = form.get(key);

                if (control.invalid) errors = {...errors, ...control.errors};

                if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) 
                {
                    recursiveFunc(control);
                }
            });
        }

        recursiveFunc(formToInvestigate);

        return errors;
    }

    /**
     * Pesquisa recursivamente um FormGroup | FormArray retornando o primeiro objectos de erro que for encontrado nos vários FormControl que possam existir.
     * @param formToInvestigate FormGroup | FormArray
     */
    static GetFirstInvalidControl(form:UntypedFormGroup | UntypedFormArray):string
    {
        const errors = FormValidators.GetInvalidControls(form);
        
        const firstErrorKey = Object.keys(errors)[0];

        if (firstErrorKey) { return errors[firstErrorKey]; }

        return null;
    }

    /**
     * Copia do validador Angular com mensagem de erro custom.
     * @param control AbstractControl
     */
    static RequiredValidator(control: AbstractControl): ValidationErrors | null 
    {
        return FormValidators.isEmptyInputValue(control.value) ? FormValidators.ErrorMessages.required : null;
    }

    /**
     * Usado para toggle que tem que ter valor positivo.
     * @param control AbstractControl
     */
    static RequiredCheckValidator(control: AbstractControl): ValidationErrors | null 
    {
        return FormValidators.isTrueInputValue(control.value) ? FormValidators.ErrorMessages.requiredConcent : null;
    }

    /**
     * Valida inputs do tipo email.
     * @param control AbstractControl
     */
	static EmailValidator(control:AbstractControl): ValidationErrors | null
	{
		if (control.pristine || control.value == "")
		{
			return null;
        }
        
		control.markAsTouched();

		if (FormValidators.EMAIL_REGEXP.test(control.value)) return null;

		return FormValidators.ErrorMessages.invalidEmail;
	}

	/**
	 * Valida input de URL.
	 * @param control 
	 */
	static UrlValidator(control:AbstractControl): ValidationErrors | null
	{
		if (control.pristine || control.value == "") return null;

		control.markAsTouched();

		if (FormValidators.URL_REGEXP.test(control.value)) return null;

		return FormValidators.ErrorMessages.invalidUrl;
    }
    
    /**
     * Valida qualidade da password inserida no momento de criação.
     * @param control 
     */
	static PasswordStrengthValidator(control: AbstractControl): ValidationErrors | null
	{
		if (control.pristine) return null;

		let score = FormValidators.HelperGetPasswordStrength(control.value);

		control.markAsTouched();

		if (score > 65) return null;

		return FormValidators.ErrorMessages.invalidPasswordStrength;
    }

    /**
	 * Valida match entre dois inputs distintos mas que o seu valor deva ser igual.
	 * @param password password input original
	 * @param repeated password repetida para verificação igualdade
	 */
	static MatchValidator(originalControlName: string, matchingControlName: string)
	{
        return (formGroup: UntypedFormGroup) => 
        {
            const originalControl = formGroup.controls[originalControlName];

            const matchingControl = formGroup.controls[matchingControlName];
            
            if (!originalControl || !matchingControl) return;

            if (matchingControl.pristine || matchingControl.value == "") return null;
        
		    matchingControl.markAsTouched();
    
            if (matchingControl.errors && !FormValidators.ErrorMessages.invalidPasswordMatch) 
            {
                return;
            }

            if (originalControl.value !== matchingControl.value) 
            {
                matchingControl.setErrors(FormValidators.ErrorMessages.invalidPasswordMatch);
            } 
            else 
            {
                matchingControl.setErrors(null);
            }
        }
    }

	/**
	 * Valida input de números.
	 * @param control AbstractControl
	 */
	static NumberValidator(control: AbstractControl): ValidationErrors | null
	{
        if (control.pristine || control.value == "") return null;
        
		control.markAsTouched();

		if (FormValidators.NUMBER_REGEXP.test(control.value)) return null;

		return FormValidators.ErrorMessages.invalidNumber;
	}

    /**
     * Validador de quatidade minima de chars.
     * @param minLength numero minimo de chars
     * @returns 
     */
    static MinLength(minLength: number): ValidatorFn 
    {
        return (control: AbstractControl): ValidationErrors|null => 
        {
            if (FormValidators.isEmptyInputValue(control.value) || !FormValidators.hasValidLength(control.value)) 
            {
                return null;
            }
    
            return control.value.length < minLength ?  FormValidators.ErrorMessages.invalidminlength :  null;
        };
    }

    /**
     * Validador de quatidade maxima de chars.
     * @param maxLength numero maximo de chars
     * @returns 
     */
    static MaxLength(maxLength: number): ValidatorFn 
    {
        return (control: AbstractControl): ValidationErrors|null => 
        {
            return FormValidators.hasValidLength(control.value) && control.value.length > maxLength ?  FormValidators.ErrorMessages.invalidmaxlength :  null;
        };
    }

	/**
	 * Valida números de telefone ou telemovel.
	 * Não inclui validação para números voip.
	 * @param control AbstractControl
	 */
	static TelephoneValidator(control: AbstractControl): ValidationErrors | null
	{
		if (control.pristine || control.value == "")
		{
			return null;
        }
        
		control.markAsTouched();

		if (FormValidators.PHONE_REGEXP.test(control.value)) return null;

		return FormValidators.ErrorMessages.invalidTelephone;
	}

	/**
	 * Valida input de números de código postal.
	 * Verifica o seguinte padrão 0000-000.
	 * @param control AbstractControl
	 */
	static ZipCodeValidator(control: AbstractControl): ValidationErrors | null
	{
		if (control.pristine || control.value == "")
		{
			return null;
		}

		control.markAsTouched();

		if (FormValidators.ZIP_REGEXP.test(control.value)) return null;

		return FormValidators.ErrorMessages.invalidZipCode;
    }

    /**
	 * Custom validator de nifs portugueses.
	 * @param control 
	 */
    static NIFValidator(control: AbstractControl): ValidationErrors | null 
    {
        if (control.pristine || control.value == "")
        {
            return null;
        }
 
        if (control.value && control.value.length == 9) 
        {
            const nif = typeof control.value === 'string' ? control.value : control.value.toString();
 
            const validationSets = 
            {
                one: ['1', '2', '3', '5', '6', '8'],
                two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
            };
 
            if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) 
            {
                return FormValidators.ErrorMessages.invalidNIF;
            }
            
            const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
            const modulo11 = (Number(total) % 11);
            const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
 
            if (checkDigit === Number(nif[8])) return null;
        }
 
        return FormValidators.ErrorMessages.invalidNIF;
    }

	/**
	 * Custom validator de IBAN.
	 * @param control 
	 */
	static IBANValidator(control: AbstractControl): ValidationErrors | null 
	{
		if (control.pristine || control.value == "")
		{
			return null;
		}

		if (control.value) 
		{
			const iban = control.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
			const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

			let digits: number;

			if (!code || iban.length !== FormValidators.IBAN_CODES[code[1]])
			{
				return FormValidators.ErrorMessages.invalidIBAN;
			}

			digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => 
			{
				return letter.charCodeAt(0) - 55;
			});

			return FormValidators.mod97(digits) === 1 ? null : FormValidators.ErrorMessages.invalidIBAN;
		}

		return FormValidators.ErrorMessages.invalidIBAN;
	}

    /**
     * Verifica se a extensão de um ficheiro é válida.
     * @param allowedExtensions array strings com o as extensões permitidas (pnd, jpg, jpeg, doc, pdf, etc.).
     * @returns 
     */
    static FileExtensionsValidator(allowedExtensions: Array<string>): ValidatorFn 
    {
        const validatorFn = (file: File) => 
        {
            if (allowedExtensions.length === 0) 
            {
                return null;
            }
    
            if (file instanceof File)
            {
                const ext = FormValidators.getExtension(file.name);

                if (allowedExtensions.indexOf(ext) === -1) 
                {
                    const error = JSON.parse(JSON.stringify(FormValidators.ErrorMessages.invalidFileType));
                
                    error.invalidFileType = error.invalidFileType.replace("#filetypes#", ` ${allowedExtensions.toString() }.`);

                    return error;
                }
            }
        };
        
        return FormValidators.fileValidation(validatorFn);
    }
    
    /**
     * Verifica a validade de um ficheiro File pelo seu tamanho máximo.
     * @param maxSizeInKB number em Kb
     * @returns 
     */
    static MaxFileSizeValidator(maxSizeInKB: number):ValidatorFn
    {
        const validatorFn = (file: File) => 
        {
            const fileSizeInKB = Math.round(file.size / 1024);

            if (file instanceof File &&  fileSizeInKB > maxSizeInKB) 
            {
                const error = JSON.parse(JSON.stringify(FormValidators.ErrorMessages.invalidMaxFileSize));
                
                error.invalidMaxFileSize = error.invalidMaxFileSize.replace("#maxfilesize#", ` ${maxSizeInKB / 1024}Mb.`);

                return error;
            }
        };

        return FormValidators.fileValidation(validatorFn);
    };

    /**
     * Verifica a validade de um ficheiro File pelo seu tamanho minimo.
     * @param minSizeInKB number em Kb
     * @returns 
     */
    static MinFileSizeValidator(minSizeInKB: number): ValidatorFn 
    {
        const validatorFn = (file: File) => 
        {
            const fileSizeInKB = Math.round(file.size / 1024);

            if (file instanceof File && file.size < minSizeInKB) 
            {
                const error = JSON.parse(JSON.stringify(FormValidators.ErrorMessages.invalidMinFileSize));
                
                error.invalidMinFileSize = error.invalidMinFileSize.replace("#minfilesize#", ` ${minSizeInKB / 1024}Mb.`);

                return error;
            }
        };

        return FormValidators.fileValidation(validatorFn);
    }
    
	/**
	 * Valida por uma quantidade mínima de chars.
	 * @param minLength quantidade minima de chars exigidos
	 * @returns 
	 */
    static MinLengthValidator(minLength: number): ValidatorFn 
	{
        return (control: AbstractControl): ValidationErrors | null => 
		{
          	if (FormValidators.isEmptyInputValue(control.value) || !FormValidators.hasValidLength(control.value)) 
			{
        		return null;
          	}
    
          	return control.value.length < minLength ?  FormValidators.ErrorMessages.invalidminlength :  null;
        };
    }

	/**
	 * Valida por uma quantidade máxima de chars.
	 * @param maxLength quantidade máxima de chars exigidos
	 * @returns 
	 */
    static MaxLengthValidator(maxLength: number): ValidatorFn 
	{
        return (control: AbstractControl): ValidationErrors | null => 
		{
            return FormValidators.hasValidLength(control.value) && control.value.length > maxLength ?  FormValidators.ErrorMessages.invalidmaxlength :  null;
        };
    }

    
}