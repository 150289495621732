
export class AsyncUtils 
{
    /**
     * Cria promise que se resolve automaticamente ao final de de determinado delay.
     * @param delay duração do sleep em ms, valor default 1000ms - 1 segundo.
     * @returns 
     */
    public static Sleep(delay:number = 1000):Promise<any>
    {
        delay = isNaN(delay) ? 1000 : delay;

        return new Promise(resolve => setTimeout(resolve, delay));
    }
}

