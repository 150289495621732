// import { IconModeNameDirective } from './icon-mode/icon-mode.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomScrollbarDirective } from './scrollbar/scrollbar.ioncontent.directive';
import { ActionsPermissionsDirective } from './actions-permissions/actions-permissions.directive';


const directives = [
	// IconModeNameDirective,
	CustomScrollbarDirective,
	ActionsPermissionsDirective
];

@NgModule({
	declarations: [directives],
	imports: [
		CommonModule,
	],
	exports: [directives]
})
export class DirectivesModule { }
