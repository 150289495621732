<form [formGroup]="FormGroup" *ngIf="FormGroup">

	<ion-grid class="full">
		<ion-row>
			<ion-col size="12" class="ion-no-padding ion-padding-top">
				<ion-title class="ion-no-padding">{{Title || '--'}}</ion-title>
			</ion-col>

			<ion-col size="12" class="ion-no-padding ion-padding-bottom">
				<ion-row>
					<ion-col size="12" sizeMd="6">
						<ion-item class="full" lines="full" fill="outline">
							<ion-label position="stacked" i18n=":@@address_default_form.distric"
								[attr.required]="Required">Distrito</ion-label>
							<ion-input formControlName="District"></ion-input>
						</ion-item>

						<form-errors [control]="FormGroup.get('District')"></form-errors>
					</ion-col>

					<ion-col size="12" sizeMd="6">
						<ion-item class="full" lines="full" fill="outline">
							<ion-label position="stacked" i18n=":@@address_default_form.county"
								[attr.required]="Required">Concelho</ion-label>
							<ion-input formControlName="County"></ion-input>
						</ion-item>

						<form-errors [control]="FormGroup.get('County')"></form-errors>
					</ion-col>

					<ion-col size="12" sizeMd="8">
						<ion-item class="full" lines="full" fill="outline">
							<ion-label position="stacked" i18n=":@@address_default_form.locality"
								[attr.required]="Required">Localidade</ion-label>
							<ion-input formControlName="Locality"></ion-input>
						</ion-item>

						<form-errors [control]="FormGroup.get('Locality')"></form-errors>
					</ion-col>

					<ion-col size="12" sizeMd="4">
						<ion-item class="full" lines="full" fill="outline">
							<ion-label position="stacked" i18n=":@@address_default_form.zipcode"
								[attr.required]="Required">Código
								postal</ion-label>
							<ion-input formControlName="ZipCode"></ion-input>
						</ion-item>

						<form-errors [control]="FormGroup.get('ZipCode')"></form-errors>
					</ion-col>
					<ion-col size="12">
						<ion-item class="full" lines="full" fill="outline">
							<ion-grid class="full">
								<ion-row>
									<ion-label style="margin-bottom: 11px;" position="stacked"
										i18n=":@@address_default_form.name" [attr.required]="Required">Rua</ion-label>
								</ion-row>
								<ion-row>
									<ion-col>
										<ion-input formControlName="Address"
											(keyup.enter)="OnSearchStreet(FormGroup)"></ion-input>
									</ion-col>
									<ion-col [hidden]="FormGroup.disabled" style="max-width: 110px;"
										class="display-flex ion-justify-content-end">

										<ng-container *ngIf="MapOptions?.gestureHandling == 'auto'">
											<ion-button title="cancelar" (click)="OnCancelRepositionMarker(FormGroup)">
												<ion-icon name="close-outline" slot="icon-only"></ion-icon>
											</ion-button>
											<ion-button title="guardar posição" (click)="OnEnabledMarker(FormGroup)">
												<ion-icon name="checkmark-outline" slot="icon-only"></ion-icon>
											</ion-button>
										</ng-container>

										<ng-container *ngIf="MapOptions?.gestureHandling != 'auto'">

											<ion-button title="localizar" (click)="OnSearchStreet(FormGroup)">
												<ion-icon name="locate-outline" slot="icon-only"></ion-icon>
											</ion-button>
											<ion-button title="corrigir posição" (click)="OnEnabledMarker(FormGroup)">
												<ion-icon name="pin-outline" slot="icon-only"></ion-icon>
											</ion-button>
										</ng-container>

									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-item>

						<form-errors [control]="FormGroup?.get('Address')"></form-errors>
					</ion-col>
				</ion-row>
			</ion-col>

			<ion-col size="12">

				<google-map-container *ngIf="MapOptions" [class]="Size" [mapId]="MapId" [options]="MapOptions"
					[pin]="FormGroup.get('Coordinates').value" [onDrag]="OnDragMarker"></google-map-container>

				<form-errors [control]="FormGroup?.get('Coordinates').get('Latitude')"></form-errors>
			</ion-col>
		</ion-row>
	</ion-grid>
</form>