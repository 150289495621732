<ion-grid class="table height-toolbar-2">

	<ion-row class="table-header">
		<ion-col size="2" class="flex-align-center">#</ion-col>
		<ion-col size="6" class="flex-align-center" i18n=":@@configurations_edition_table.edition">Edição</ion-col>
		<ion-col size="2" class="flex-align-center" i18n=":@@configurations_edition_table.date">Inscrição aberta</ion-col>
		<ion-col size="2" class="flex-align-center" i18n=":@@configurations_edition_table.type"></ion-col>
		<!-- <ion-col size="2" class="flex-align-center"></ion-col> -->
	</ion-row>

	<!-- <ng-container *cdkVirtualFor="let item of Data; let itemBounds = bounds;"> -->
	<ng-container *ngFor="let item of Data;">

		<ion-row class="table-body with-button-opacity">
			<ion-col size="2" class="flex-align-center">{{ item?.Id }}</ion-col>
			<ion-col size="6" class="flex-align-center">{{ item?.Name }}</ion-col>

			<ion-col size="2" class="flex-align-center">
				{{ item?.RegistrationOpened ? 'Sim': 'Não' }}
			</ion-col>

			<ion-col size="2" class="flex-justify-end">

				<ion-button fill="clear" size="small" (click)="OnEdit(item)">
					<ion-icon iconMode name="create-outline" slot="icon-only"></ion-icon>
				</ion-button>

				<ion-button [disabled]="item?.Id==SeletedEdition?.Id" fill="clear" size="small"
					(click)="OnDelete(item)">
					<ion-icon iconMode name="trash-outline" slot="icon-only"></ion-icon>
				</ion-button>

			</ion-col>
		</ion-row>

	</ng-container>

	<ion-row *ngIf="!Data || Data?.length == 0">
		<ion-col>
			<no-items></no-items>
		</ion-col>
	</ion-row>

	<!-- <pagination-control [totalItems]="Data?.length" (pageChange)="OnChangePage($event)">
	</pagination-control> -->

</ion-grid>