<ion-header>
	<ion-toolbar class="ion-padding-end">
		<ion-title i18n=":@@booking_review.header-title">Informação de entrega</ion-title>


		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">

	<ion-grid class="full">

		<ion-row>

			<ion-col size="12" class="communication" [ngClass]="CanSendMessage?'':'full-height'">

				<div class="chat-area">

					<ng-container *ngFor="let item of Data?.CommunicationList">

						<ion-row *ngIf="item?.Observations" class="ion-padding"
							[ngClass]="item?.User?.Id==IdUser ? 'right' : ''">

							<div class="card">
								<div>
									<!-- <h3>doador</h3> -->
									<span>{{item?.CreateDate|date:'dd/MM/YYYY HH:mm:ss'}}</span>
								</div>
								<div>
									<p>{{item?.Observations}}</p>
								</div>
							</div>

						</ion-row>

					</ng-container>

					<no-items *ngIf="Data?.CommunicationList?.length==0" label="sem mensagens"
						icon="mail-open-outline"></no-items>

				</div>
			</ion-col>

			<ion-col size="12" class="ion-padding-horizontal" *ngIf="CanSendMessage">

				<form [formGroup]="Form" class="full">

					<ion-item class="full" lines="full">
						<!-- <ion-label position="stacked" i18n=":@@booking_review.message">Mensagem
						</ion-label> -->
						<ion-textarea rows="3" placeholder="mensagem a enviar"
							formControlName="Observations"></ion-textarea>
					</ion-item>

					<form-errors [control]="Form.get('Observations')"></form-errors>
				</form>

			</ion-col>

		</ion-row>

	</ion-grid>

</ion-content>

<ion-footer *ngIf="CanSendMessage">
	<ion-toolbar>
		<ion-toolbar class="ion-padding-horizontal">

			<!-- <ion-button style="margin-right: 8px;" slot="end" fill="clear" color="danger" title="Cancelar reserva"
				(click)="OnCancelBooking($event)">
				<ion-icon name="close-outline" slot="icon-only"></ion-icon>
			</ion-button> -->

			<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium"
				i18n=":@@booking_review.save" (click)="OnChangeBookingInfo($event)">Enviar
				<ion-icon slot="end" name="send-outline"></ion-icon>
			</ion-button>


		</ion-toolbar>
	</ion-toolbar>
</ion-footer>