import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EnumPageRow } from '@models/Common';

@Component({
	selector: 'pagination-control',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {

	@Input() page: number;
	@Input() totalItems: number;

	@Input() itemsPerPage: number[];
	@Input() disabled: boolean = false;

	@Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

	@Input() itemsForPageSelected: number;
	public Pages: number[] = [];

	constructor() {

	}

	ngOnChanges(changes: SimpleChanges): void {

		console.log(changes);


		this.itemsPerPage = this.itemsPerPage ?? [EnumPageRow.R25, EnumPageRow.R50, EnumPageRow.R100];

		this.itemsForPageSelected = this.itemsForPageSelected ?? this.itemsPerPage[0];

		console.log("change pagination");

		this.CalcNumberPages();
	}

	public CalcNumberPages(ev?: any) {

		this.Pages.length = 0;

		const value = ev?.detail?.value ?? null;

		if (value && this.disabled) return;

		if (value)
			this.itemsForPageSelected = value;

		const numberOfPages = Math.ceil(this.totalItems / this.itemsForPageSelected);

		for (let index = 0; index < numberOfPages; index++) {
			this.Pages.push(index + 1);

		}

		if (this.Pages)
			this.page = !value && this.page ? this.page : this.Pages[0];

		if (value) {
			this.pageChange.emit({ page: this.page, itemsPerPage: this.itemsForPageSelected })
		}


	}

	GoPage(page: number) {

		if (this.disabled) return;

		if (!page || this.page == page) return;

		this.page = page;

		this.pageChange.emit({ page: this.page, itemsPerPage: this.itemsForPageSelected })
	}

	PreviousPage() {

		if (this.disabled) return;

		if (this.page <= 0) return;

		this.page--;

		this.pageChange.emit({ page: this.page, itemsPerPage: this.itemsForPageSelected })
	}

	NextPage() {

		if (this.disabled) return;

		if (this.Pages.length <= this.page) return;

		this.page++;

		this.pageChange.emit({ page: this.page, itemsPerPage: this.itemsForPageSelected })
	}

}
