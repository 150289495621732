import { UsersService } from './users.service';
import { IUserInfoModel } from './../models/User';
import { SessionService } from './session.service';
import { WebService } from './web.service';
import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { IAuthCredentials, ISession, Session } from '../models/AuthUser';
import { EnumStorageKeys, StorageService } from './storage.service';


@Injectable(
    {
        providedIn: 'root'
    })
export class AuthService {


    constructor(private WebService: WebService,
        private StorageService: StorageService,
        // private AppMenusService: AppMenusService,
        private SessionService: SessionService,
        private UsersService: UsersService,
        private NavController: NavController,
        private AlertController: AlertController) {

    }

    async Login(auth: IAuthCredentials) {

        try {

            await this.WebService.SetAuthorization(null);
            await this.WebService.SetEntityId(null);

            const response = await this.WebService.Login(auth);

            if (response?.IsSuccessStatus) {

                const session: ISession = new Session(response.Result);

                await this.WebService.SetAuthorization(session.AccessToken);

                //ADICIONA DE IMEDIATO TOKEN NO HEADER PARA FUTUROS REQUESTS
                //CASO O TOKEN SEJA INVALIDO E PEDIDOS FALHEM NO WORKFLOW DE LOGIN
                //SERA EFECTUADO UM "Logout" E O TOKEN REMOVIDO

                //LIMPA REFERENCIA DE MENUS EXISTENTE, ASSUMINDO QUE NO FUTURO OS MENUS PODEM ESTAR COM DEPENDENCIAS AO UTILIZADOR
                // this.AppMenusService.ClearUserMenus();

                this.SessionService.SaveSession(session);

                return session;

            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    async Logout() {

        try {
            this.WebService.Logout();

            await this.WebService.SetAuthorization(null);

            //AO EFECTUAR LOGIN, ESTA REFERENCIA ESTA A SER SEMPRE LIMPA
            //MAS POR SEGURANCA, LIMPA REFERENCIA NO PROPRIO PROCESSO DE LOGIN
            // this.AppMenusService.ClearUserMenus();

            this.SessionService.sessionLossEmited = true;

            await this.SessionService.SaveSession(null);

            return true;
        }
        catch (error) {
            this.WebService.SetAuthorization(null);

            //AO EFECTUAR LOGIN, ESTA REFERENCIA ESTA A SER SEMPRE LIMPA
            //MAS POR SEGURANCA, LIMPA REFERENCIA NO PROPRIO PROCESSO DE LOGIN
            // this.AppMenusService.ClearUserMenus();

            this.SessionService.SaveSession(null);

            return false;
        }
    }

    /**
     * 
     * @returns 
     */
    async GetUserInfo(): Promise<IUserInfoModel> {

        try {

            // get roles
            // const responseRoles = await this.UsersService.GetRoleUsersList();

            const response = await this.WebService.GetUserInfo();

            // console.log(userData);
            // set role
            // userData.Role = responseRoles.find(it => it.Id == userData?.IdRole);

            return response?.Result || null;

        } catch (error) {
            return error;
        }
    }

    /**
     * 
     * @param email 
     * @returns 
     */
    async RecoverPassword(email) {

        const data = {
            // "code": "string",
            // "idUser": "string",
            // "idRole": 0,
            // "name": "string",
            // "username": "string",
            email: email,
            // "password": "string",
            // "confirmPassword": "string"
        }

        const response = await this.WebService.RecoverPassword(data);

        return response;
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ResetPassword(data: { password: string, code: string }) {

        const submit = {
            code: data?.code,
            // "idUser": "string",
            // "idRole": 0,
            // "name": "string",
            // "username": "string",
            // email: email,
            password: data?.password,
            confirmPassword: data?.password
        }

        const response = await this.WebService.ResetPassword(submit);

        return response;
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ChangePassword(data: { idUser: string, oldPassword: string, password: string, confirmPassword: string }) {

        const submit = {
            idUser: data.idUser,
            // "idRole": 0,
            // "name": "string",
            // "username": "string",
            // email: email,
            oldPassword: data?.oldPassword,
            password: data?.password,
            confirmPassword: data?.confirmPassword
        }

        const response = await this.WebService.ChangePassword(submit);

        return response;
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ChangePersonalData(data: { idUser: string, name: string }) {

        const submit = {
            idUser: data.idUser,
            // "idRole": 0,
            name: data?.name || '',
            // "username": "string",
            // email: email,
            // password: data?.password,
            // confirmPassword: data?.confirmPassword
        }

        const response = await this.WebService.ChangePersonalData(submit);

        return response;
    }

    /**
     * 
     * @param id 
     */
    async SetEntity(entity: { Id: string, Name: string }) {

        try {

            const session = this.SessionService.Get();

            session.Entity = entity || null;

            this.SessionService.SaveSession(session);

        } catch (error) {

        }
    }
}