<ion-header>
	<ion-toolbar class="ion-padding-end">
		<ion-title *ngIf="Delivery" i18n=":@@booking_delivery.header-title">Doação</ion-title>
		<ion-title *ngIf="!Delivery" i18n=":@@booking_delivery.header-title-2">Reserva</ion-title>

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">


	<ion-grid class="full">
		<ion-row>
			<!-- <ion-card class="ion-no-margin ion-no-padding full">

				<ion-card-content> -->

			<form [formGroup]="Form" class="full">

				<ion-row *ngIf="Form.get('ArticleName').value">
					<ion-col>
						<ion-title class="ion-no-padding ion-no-margin">
							<ion-text>
								{{Form.get('ArticleName').value||'--'}}</ion-text>

							<ion-button (click)="OpenDonation($event)"
								style="margin-top: -8px !important; font-size: 10px; position: absolute; margin-left: 8px;"
								title="visualizar doação" size="small" fill="clear" color="dark" shape="round">
								<ion-icon slot="icon-only" name="open-outline"> </ion-icon>
							</ion-button>

						</ion-title>

					</ion-col>
				</ion-row>

				<ion-row>

					<ion-col size="12">
						<ion-item class="full" lines="full">
							<ion-label position="stacked" i18n=":@@booking_delivery.username">Nome de utilizador
							</ion-label>
							<ion-input formControlName="User" readonly></ion-input>
						</ion-item>

						<form-errors [control]="Form.get('User')"></form-errors>
					</ion-col>

				</ion-row>

				<ng-container *ngIf="Delivery">
					<ion-row>

						<ion-col size="6">
							<ion-item class="full" lines="full">
								<ion-label position="stacked" i18n=":@@booking_delivery.quantity-taken"
									required>Quantidade
								</ion-label>
								<ion-input formControlName="QuantityTaken" type="number"
									placeholder="ex: 1"></ion-input>
							</ion-item>

							<form-errors [control]="Form.get('QuantityTaken')"></form-errors>
						</ion-col>

					</ion-row>

					<ion-row>
						<ion-col size="12">
							<ion-item class="full" lines="full">
								<ion-label position="stacked" i18n=":@@booking_delivery.observations"
									required>Observações
								</ion-label>
								<ion-textarea style="padding: 2px;" rows="4"
									formControlName="Observations"></ion-textarea>
							</ion-item>

							<form-errors [control]="Form.get('Observations')"></form-errors>
						</ion-col>

					</ion-row>

					<ion-row>
						<ion-col size="12">
							<ion-item class="full ion-no-margin" lines="full">
								<ion-label i18n=":@@booking_delivery.finnish"
									style="white-space: normal; overflow: unset; font-weight: normal;">Ao selecionar
									esta opção, indica
									que pretende finalizar a doação.
								</ion-label>
								<ion-checkbox formControlName="CloseArticle" slot="start"></ion-checkbox>
							</ion-item>

						</ion-col>

					</ion-row>
				</ng-container>

				<ng-container *ngIf="!Delivery">
					<!-- 
					<ion-row>
						<ion-col size="12">
							<ion-item class="full" lines="full">
								<ion-label position="stacked" i18n=":@@booking_delivery.schedule">Horário de
									entrega
								</ion-label>
								<ion-input formControlName="DeliverySchedule"
									placeholder="ex: 18h00 - 21h00"></ion-input>
							</ion-item>

							<form-errors [control]="Form.get('DeliverySchedule')"></form-errors>
						</ion-col>

					</ion-row> -->

					<ion-row>
						<ion-col size="12">
							<ion-item class="full" lines="full">
								<ion-label position="stacked" i18n=":@@booking_delivery.description" required>Descrição
									de
									entrega
								</ion-label>
								<ion-textarea style="padding: 2px;" rows="6" formControlName="DeliveryDescription"
									[placeholder]="'Deve indicar o dia ou mesmo os dias em que pode fazer a entrega como também pode descrever o local da entrega. \n\nTenha atenção para não expor os seus dados pessoais.'"></ion-textarea>
							</ion-item>


							<form-errors [control]="Form.get('DeliveryDescription')"></form-errors>
						</ion-col>

					</ion-row>

				</ng-container>
			</form>
			<!-- </ion-card-content>
			</ion-card> -->
		</ion-row>
	</ion-grid>


</ion-content>

<ion-footer>
	<ion-toolbar>
		<ion-toolbar class="ion-padding-horizontal">

			<ng-container *ngIf="!Delivery">
				<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium"
					(click)="OnAcceptBooking($event)">Aceitar reserva
				</ion-button>
			</ng-container>

			<ng-container *ngIf="Delivery">
				<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium"
					(click)="OnAcceptBooking($event)">Entregar doação
				</ion-button>
			</ng-container>

		</ion-toolbar>
	</ion-toolbar>
</ion-footer>