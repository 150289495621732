import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import Quill from 'quill';


@Component({
	selector: 'text-editor-quill',
	templateUrl: './text-editor-quill.component.html',
	styleUrls: ['./text-editor-quill.component.scss'],
})
export class TextEditorQuillComponent implements OnInit, OnChanges {

	@Input() source: any;
	@Input() disabled: boolean;
	@Output() callback: EventEmitter<any> = new EventEmitter();

	public quillId: string;
	public editor;
	private cursorPosition;

	constructor() {

		this.quillId = 'quill_' + (Math.random() + 1).toString(36).substring(7);
	}

	async ngOnInit() {
		await this.initQuill();

	}

	async ngOnChanges() {

		await this.initQuill();

		// console.log(this.source);

		if (!this.editor) return;
		// console.log(this.source.charAt(0) != '<');

		this.editor.enable(true);

		if (this.source?.charAt(0) != '<')
			this.editor.setText(this.source || '');
		else
			this.editor.root.innerHTML = this.source;

		// if (this.disabled)
		// 	this.editor.enable(false);

	}

	private async initQuill() {

		//DELAY DE CARREGAMENTO
		await new Promise(r => setTimeout(r, 1));

		if (this.editor) return;

		const txtArea = document.createElement('textarea');

		if (!document.getElementById(this.quillId)) return;

		const icons = Quill.import('ui/icons');

		icons['showHtml'] = '<b>HTML</b>';
		// icons['showTags'] = '<b>TAGS</b>';
		// icons['tag1'] = '<b>TAG 1</b>';
		// icons['tag2'] = '<b>Tag 2</b>';


		this.editor = new Quill(`#${this.quillId}`, {
			modules: {
				toolbar: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike'],
					// ['image','link'],
					['link'],
					[{ 'color': [] }, { 'background': [] }],
					[{ 'indent': '-1' }, { 'indent': '+1' }],
					// [{ 'script': 'sub' }, { 'script': 'super' }],
					[{ 'list': 'ordered' }, { 'list': 'bullet' }],
					[{ 'align': [] }],
					['clean'],
					// ['code-block'],
					['showHtml']
				],
				// imageResize: {
				// 	displaySize: true
				// }, 
				// syntax: true,    
			},


			placeholder: 'adicione o seu texto aqui...',
			theme: 'snow'  // or 'bubble');
		});

		// if (this.editor)
		// 	this.editor.enable(false);


		this.editor.on('text-change', (delta, oldDelta, source) => {

			txtArea.value = this.editor.root.innerHTML;

			this.cursorPosition = this.editor.getSelection();

			// console.log(delta, oldDelta, source);
			if (source == 'api') {
				// console.log("An API call triggered this change.");
			} else if (source == 'user') {
				// console.log("A user action triggered this change.");
				// console.log(txtArea.value);
				if (this.callback)
					this.callback.emit(txtArea.value != "<p><br></p>" ? txtArea.value : null);

				// this.source = txtArea.value;
			}
		});


		this.editor.on('selection-change', (range, oldRange, source) => {
			this.cursorPosition = this.editor.getSelection() || this.cursorPosition;

			// if (range) {
			// 	if (range.length == 0) {
			// 		console.log('User cursor is on', range.index);
			// 	} else {
			// 		// const text = this.editor.getText(range.index, range.length);
			// 		// console.log('User has highlighted', text);
			// 	}
			// } else {
			// 	console.log('Cursor not in the editor');
			// }
		});

		const htmlEditor = this.editor.addContainer('ql-custom');
		htmlEditor.style.display = 'none';

		htmlEditor.appendChild(txtArea);

		const customButtonHTML = document.querySelector('.ql-showHtml');
		// // const customButtonTAGS: any = document.querySelector('.ql-tags');

		customButtonHTML.addEventListener('click', () => {

			if (this.disabled) return;

			if (txtArea.style.display === '')
				this.editor.root.innerHTML = txtArea.value;

			htmlEditor.style.display = htmlEditor.style.display === 'none' ? '' : 'none';

		});

		// customButtonTAGS.addEventListener('click', (e) => {
		// 	const v: any = document.querySelector('.ql-picker-item.ql-selected');

		// 	if (!v || !v.dataset?.value) return;

		// 	if (!customButtonTAGS.classList.value.includes('ql-expanded')) {
		// 		this.editor.insertText(this.cursorPosition?.index || 0, v.dataset?.value);
		// 		// this.editor.insertText(this.cursorPosition?.index || 0, "#" + v.dataset?.value + "#");
		// 	}
		// 	v.classList.remove('ql-selected');

		// });

		// if (!this.source) return;


		// console.log(this.source);

		// this.editor.setText(this.source);

		return;
	}

}
