import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

@Component({
	selector: 'app-present-image',
	templateUrl: './present-image.component.html',
	styleUrls: ['./present-image.component.scss'],
})
export class PresentImageModalComponent implements OnInit {

	public Image: string;

	constructor(
		private ModalController: ModalController,
		private NavParams: NavParams
	) {


		this.Image = this.NavParams.get('image') || '';
	}

	ngOnInit() { }

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

}
