import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { EnumAPIStatus } from '@api/AbstractAPI';

@Component({
	selector: 'app-item-select',
	templateUrl: './item-select.component.html',
	styleUrls: ['./item-select.component.scss'],
})
export class ItemSelectModalComponent implements OnInit {

	public Data: any[] = [];

	private onSaveItem: Function;

	constructor(
		private NavParams: NavParams,
		private ModalController: ModalController
	) {

		this.Data = this.NavParams?.get('data') || [];
		this.onSaveItem = this.NavParams?.get('onSave') || null;

		console.log(this.Data);

	}

	ngOnInit() { }

	public async OnAddItem(item) {

		if (this.onSaveItem) {
			const result = await this.onSaveItem(item);


			if (result?.Status == EnumAPIStatus.Success)
				this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Confirm);
				
			console.log(result);

		}
	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

}
