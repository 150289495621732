import { IEditionBaseModel } from '@models/Edition';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertButton, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { EnumActionsPermissions } from '@models/EnumPermissions';
import { Subscription } from 'rxjs';

export enum EnumGenericPageMessages {
    NoDataForSelectedFilter = "Não existem dados para a seleção atual.",
    ErrorOperationData = "Ocorreu um problema na operação solicitada.",
    ErrorRequestingData = "Ocorreu um problema a obter os dados.",
    SubmettingData = "A enviar dados.",
    DataSubmitted = "Dados enviados com sucesso.",
    DataDeleted = "Dados removidos com sucesso."
}

export enum EnumGenericRoleActionAlerts {
    Cancel = "cancel",
    Confirm = "confirm",
    Save = "save",
    Close = "close",
    Error = "error"
}

export enum EnumGenericPageActions {
    None = '',
    Refresh = 'refresh',
}
export interface PageQueryParams {
    id?: string,
    date?: string,
    action?: string,
    idLawCategory?: string,
    step?: string,
    token?: string,
}

@Directive()
export class BasePage implements OnInit, OnDestroy {
    /**
     * Helper Usada para controlo manual de estados de pedidos ao servidor.
     * Sempre que é invocado um loading é atribuido a true e sempre que é removido o loading e colocado a false.
     */
    public IsRequestingData: boolean;

    /**
     * Helper para operacoes de CRUD e validacoes enquando são efectuados pedidos ao servidor.
     * Deve ser atribuido manualmente no inicio e final do request de acordo com o pretendido.
     */
    public IsSubmitting: boolean;

    /**
     * Subscrição ao Observable que contem o bind às alterações da query string do state da aplicação.
     */
    public QueryParamsSubscription: Subscription;

    /**
     * Subscrição ao Observable que contem o bind às alterações de parametros do state da aplicação.
     */
    public ParamsSubscription: Subscription;

    /**
     * Objecto que contem os "query params" do state da página.
     * No "boot" do componente estes parametros são atribuidos pelo snapshot do router.
     * Posteriormente, a cada alteração de estado, a partir da subscrição do QueryParamsSubscription a variavel é actualizada.
     */
    public PageQueryParams: PageQueryParams;

    /**
     * Objecto que contem os "params" do state da página.
     * No "boot" do componente estes parametros são atribuidos pelo snapshot do router.
     * Posteriormente, a cada alteração de estado, a partir da subscrição do QueryParamsSubscription a variavel é actualizada.
     */
    public PageParams: Params;

    /**
     * Dados da edição selecionada
     */
    public SelectedEdition: IEditionBaseModel;

    /**
     * Lista de permissões disponiveis por perfil/utilizador
     */
    public ActionsPermissions: number[];

    /**
     * enum de permissões
     */
    public EnumActionsPermissions = EnumActionsPermissions;

    constructor(protected ActivatedRoute: ActivatedRoute,
        protected LoadingController: LoadingController,
        protected ToastController: ToastController,
        protected AlertController: AlertController) {
        this.IsRequestingData = false;

        this.IsSubmitting = false;

        this.ParseRouteParams();
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    /**
     * Adiciona loading de página completa, caso não exista loading activo.
     * Possibilidade de remover loading caso exista algum previamente activo com a adição de novo loading.
     * @returns 
     */
    async ShowLoading() {
        this.IsRequestingData = true;

        const hasLoading = await this.LoadingController.getTop();

        if (hasLoading) return;

        const loading = await this.LoadingController.create(
            {
                cssClass: "ion-loading-default",
                message: 'Aguarde, por favor...',
                spinner: "lines",
                mode: "ios"
            });

        await loading.present();

        return loading;
    }

    /**
     * Remove qualquer loading de pagina completa existente.
     * Verifica a existencia de loading a partir do metodo LoadingController.getTop().
     * Se existir loading, faz dismiss do mesmo.
     * @returns 
     */
    async DismissLoading() {
        this.IsRequestingData = false;

        const current = await this.LoadingController.getTop();

        if (current) return await current.dismiss();

        return true;
    }

    /**
     * Adiciona toast e remove toast caso exista algum previamente.
     * @param message string mensagem do toast.
     * @param duration number duração do toast em ms. Por defeito possui uma duração de 3 segundos (3000ms).
     * @param needConfirmation
     * @returns 
     */
    async ShowToast(message: string, duration: number = 3000, needConfirmation: boolean = false) {
        await this.DismissToasts();

        if (message?.length > 30) duration = (message?.length * 35);

        console.log("toast time -> ", duration);

        const toast = await this.ToastController.create(
            {
                duration: duration,
                message: message,
                cssClass: "default-toast",
                position: "top",
                mode: "ios",
                buttons:
                    [
                        {
                            icon: 'close',
                            role: EnumGenericRoleActionAlerts.Cancel,
                            handler: () => { if (toast) toast.dismiss(); }
                        }
                    ]
            });

        await toast.present();

        return toast;
    }

    /**
     * Remove qualquer toast existente invocado pela página.
     * Verifica a existencia do toast a partir do metodo ToastController.getTop().
     * Se existir toast, faz dismiss do mesmo.
     * @returns boolean 
     */
    async DismissToasts() {
        const current = await this.ToastController.getTop();

        if (current) return current.dismiss();

        return Promise.resolve(true);
    }

    /**
     * 
     * @param header 
     * @param subHeader 
     * @param message 
     * @returns 
     */
    async ShowAlert(header: string = $localize`:@@ALERT_campaign-crud.warning_header_default:AVISO`, message: string = 'Pretende realizar a operação.', buttons: AlertButton[] = undefined) {
        if (!buttons) {
            buttons = [
                {
                    text: 'cancelar',
                    role: EnumGenericRoleActionAlerts.Cancel
                },
                {
                    text: 'confirmar',
                    role: EnumGenericRoleActionAlerts.Confirm
                }
            ];
        }

        const alert = await this.AlertController.create({
            cssClass: 'default',
            header: header,
            //   subHeader: subHeader,
            message: message,
            backdropDismiss: false,
            buttons: buttons
        });

        await alert.present();

        return await alert.onDidDismiss();

    }

    /**
     * 
     * @param header 
     * @param message 
     * @param inputTitle 
     * @param value 
     * @param buttons 
     * @param disabled 
     * @returns 
     */
    async ShowAlertWithObservation(header: string = 'Aviso', message: string = 'Pretende realizar a operação.', inputTitle: string = 'Motivo', value: string = '', buttons: AlertButton[] = undefined, disabled: boolean = false) {
        if (!buttons) {
            buttons = [
                {
                    text: 'cancelar',
                    role: EnumGenericRoleActionAlerts.Cancel
                },
                {
                    text: 'confirmar',
                    role: EnumGenericRoleActionAlerts.Confirm
                }
            ];
        }

        const alert = await this.AlertController.create({
            cssClass: 'default alert-textarea',
            header: header,
            inputs: [
                {
                    label: inputTitle,
                    placeholder: inputTitle,
                    value: value,
                    id: 'obs',
                    name: 'observation',
                    type: 'textarea',
                    disabled: disabled
                }
            ],
            message: message,
            backdropDismiss: false,
            buttons: buttons
        });

        await alert.present();

        return await alert.onDidDismiss();

    }

    /**
     * Parse dos parametros do url/state.
     * Os parametros são guardados nas propriedades PageQueryParams e PageParams.
     * É efectuado o subscribe das queryParams e params ao ActivatedRoute Router.
     * O unsubscrive ao ActivatedRoute é gerido automaticamente pelo angular.
     * Na execução do metodo é lido directamente os params pelo snapshot e são actualizados pelo subscribe.
     */
    public ParseRouteParams() {
        this.PageQueryParams = this.ActivatedRoute.snapshot.queryParams;

        this.PageParams = this.ActivatedRoute.snapshot.params;

        this.QueryParamsSubscription = this.ActivatedRoute.queryParams.subscribe(queryParams => {
            this.PageQueryParams = { ...this.PageQueryParams, ...queryParams };

            //PARSE TO NUMBER DO PARAMETRO ID SE EXISTIR
            if (this.PageQueryParams.id) this.PageQueryParams.id = this.PageQueryParams.id;

            //ACTUALIZA O TITULO DA PAGINA
            // this.SetPageParamsFromMenu(this.PageQueryParams.id);
        });

        this.ParamsSubscription = this.ActivatedRoute.params.subscribe(params => {
            this.PageParams = params;
        });
    }


    /**
     * Atribui as permissões disponíveis 
     * @param permissions 
     */
    public SetActionsPermissions(permissions: number[] = []) {
        // console.log(permissions);

        this.ActionsPermissions = permissions;
    }
}
