import { Component, OnInit, Input } from '@angular/core';
import { IDashboardData, IDashboardResult } from '@models/Dashboard';

@Component({
	selector: 'card-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

	@Input('data') Data: IDashboardData;

	constructor() { }

	ngOnInit() { }

}
