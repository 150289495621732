import { EnumStorageKeys, StorageService } from '@services/storage.service';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	private darkMediaQuery;

	public IsDarkThemeEnabled: boolean;

	private style: HTMLLinkElement;
	private cssFile: string;
	private renderer: Renderer2;

	constructor(private StorageService: StorageService,
		private rendererFactory: RendererFactory2,
		@Inject(DOCUMENT) private document: Document) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	/**
	 * Inicia a predefinição do 'theme' com base na configuração do browser
	 */
	public async Init() {

		this.darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

		this.toggleDarkTheme(this.darkMediaQuery.matches);

		try {
			// Chrome & Firefox
			this.darkMediaQuery.addEventListener('change', (e) => {
				this.toggleDarkTheme(this.darkMediaQuery.matches);
			});
		}
		catch (e1) {
			try {
				// Safari
				this.darkMediaQuery.addListener((e) => {
					this.toggleDarkTheme(this.darkMediaQuery.matches);
				});
			} catch (e2) {
				console.error(e2);
			}
		}

		const preferences = await this.StorageService.Get(EnumStorageKeys.Preferences);

		//force to light theme -> [remove after]
		this.toggleDarkTheme(preferences ? preferences.darkMode : false);

		return;
	}

	/**
	 * Altera o 'theme' entre 'dark' | 'light'
	 */
	public ToggleTheme() {

		this.toggleDarkTheme(!this.IsDarkThemeEnabled);

	}

	/**
	 * Adiciona ou remove a class 'dark' o que faz alterar o 'theme'
	 * @param shouldAdd 
	 */
	private toggleDarkTheme(shouldAdd) {

		this.IsDarkThemeEnabled = shouldAdd;

		const preferences = {
			darkMode: this.IsDarkThemeEnabled
		}

		this.StorageService.Save(EnumStorageKeys.Preferences, preferences);

		document.body.classList.toggle('dark', shouldAdd);

	}

	/**
	 * 
	 * @param projectPath 
	 */
	setTheme(projectPath: string) {
		this.cssFile = `${projectPath}/styles/styles.css?v=${new Date().getTime()}`;

		this.style = this.renderer.createElement('link') as HTMLLinkElement;

		this.renderer.setProperty(this.style, 'rel', 'stylesheet');
		this.renderer.setProperty(this.style, 'href', this.cssFile);

		this.renderer.appendChild(this.document.head, this.style);
	}
}
