import { IUserInfoModel, UserInfoModel } from "./User";

export interface IAuthCredentials {
    Username: string,
    Password: string
}

export interface IAuthModel {
    AccessToken: string,
    ExpiresIn: any | number,
    RefreshToken: string,
    Entity: { Id: string, Name: string },
    TokenType: number,
}

export interface ISession extends IAuthModel {
    UserData: IUserInfoModel,
    ExpiresDateObject?: Date
}

export class Session implements ISession {
    /**
     * Access Token de sessão.
     */
    public AccessToken: string;
    /**
     * Tempo para expiração.
     */
    public ExpiresIn: number;

    public RefreshToken: string;

    public Entity: { Id: string, Name: string };

    public TokenType: number;

    public UserData: IUserInfoModel;

    /**
     * Objecto date da data de expiração.
     */
    public ExpiresDateObject: Date;

    constructor(data: Partial<ISession> = {}) {
        data = data || {};

        this.AccessToken = data.AccessToken || "";
        this.ExpiresIn = data.ExpiresIn || null;
        this.RefreshToken = data.RefreshToken || "";
        this.Entity = data.Entity || null;
        this.TokenType = data.TokenType || null;
        this.UserData = new UserInfoModel(data.UserData);

        //FOR DEBUG AND TEST
        // this.ExpiresDate = "2019-12-31T23:58:00.000Z";

        this.ExpiresDateObject = data.ExpiresIn ? data?.ExpiresDateObject ? new Date(data?.ExpiresDateObject) : new Date() : null;

        (this.ExpiresIn && this.ExpiresDateObject instanceof Date && !data?.ExpiresDateObject) ? this.ExpiresDateObject.setMinutes(this.ExpiresDateObject.getMinutes() + parseInt("" + this.ExpiresIn) || 0) : null;


    }

    public IsValid(): boolean {

        // console.log("Is Valid Model", this.ExpiresDateObject);

        if (!this.ExpiresDateObject) return false;

        //A DATA DO CLIENTE DEVE SER OBTIDA EM FUNCAO DO TIMEZONE ESPECIFICO UTC (UTC -> HORA SERVIDOR)
        //O LOCAL STRING É DEFINIDO EM "en-EN" PARA MANTER A ORDEM CORRECTA DE MES/DIA E PODER CONVERTER PARA OBJECTO new Date() AUTOMATICAMENTE
        //SE POR EXEMPLO DEFINIR "pt-PT" COMO LOCALE, VAI RETORNAR A DATA dd/mm/aaaa EM VEZ DE "mm/dd/aaaa".
        // const clientDateTimeZone: string = new Date().toLocaleString("en-EN", { timeZone: "UTC" });

        //CRIA NOVA OBJECTO Date() COM DATA/HORA DO USER EM UTC
        //JÁ VAI CONTER O DIFERENCIAL DA DATA CALCULADO EM FUNCAO DA DATA EM UTC
        // const clientDate: Date = new Date(clientDateTimeZone);


        const clientDate: Date = new Date();

        //DUPLICA/COPIA OBJECTO Date() DA DATA DE EXPIRACAO DO SERVIDOR PARA NAO MANIPULAR DIRECTAMENTE O OBJECTO BASE
        const serverDate: Date = new Date(this.ExpiresDateObject.getTime());

        // console.log(clientDate, serverDate);

        // console.log(serverDate.getTime() + " > " + clientDate.getTime(), serverDate.getTime() > clientDate.getTime());

        //SE A DATA DO SERVIDOR FOR SUPERIOR A DATA DO CLIENTE O TOKEN AINDA ESTÁ VALIDO!
        return serverDate.getTime() > clientDate.getTime();
    }
}