import { SessionService } from '@services/session.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumAppMenu } from '@models/AppMenus';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { IDonationDeliveryModel } from '@models/Donation';


@Component({
	selector: 'app-booking-review',
	templateUrl: './booking-review.component.html',
	styleUrls: ['./booking-review.component.scss'],
})
export class BookingReviewModalComponent implements OnInit {

	public Form: FormGroup;
	private showToast: Function;
	private showAlert: Function;
	private reviewBooking: Function;
	private cancelBooking: Function;

	public Data: IDonationDeliveryModel;

	public IdUser: string;

	public CanSendMessage: boolean = false;

	constructor(private NavParams: NavParams,
		private SessionService: SessionService,
		private ModalController: ModalController) {

		this.IdUser = this.SessionService.Get()?.UserData?.Id || '';

		this.Data = this.NavParams.get('data') || null;
		this.showToast = this.NavParams.get('showToast') || null;
		this.showAlert = this.NavParams.get('showAlert') || null;
		this.reviewBooking = this.NavParams.get('reviewBooking') || null;
		this.cancelBooking = this.NavParams.get('cancelBooking') || null;
		this.CanSendMessage = this.NavParams.get('canSendMessage') || false;


		console.log(this.Data);



		this.onCreateFormElements();
	}

	ngOnInit() {

	}

	private onCreateFormElements(data: IDonationDeliveryModel = this.Data) {

		this.Form = new FormGroup({
			IdDonation: new FormControl(data?.Id || null, []),
			// IdArticle: new FormControl(data?.IdArticle || null, []),
			IdStatus: new FormControl(null, []),
			Observations: new FormControl(null, [Validators.minLength(4)]),
			// ArticleName: new FormControl(data?.Article?.Name || '', []),
		})

		// console.log(this.Form);

	}

	/**
	 * 
	 * @param ev 
	 * @returns 
	 */
	async OnChangeBookingInfo(ev: any) {

		console.log(this.Form);

		if (!this.Form.dirty) return await this.showToast('Não foram encontradas alterações.');

		this.Form.markAllAsTouched();

		if (this.Form.invalid)
			return await this.showToast('Preencha todos os campos assinalados.');


		const { role } = await this.showAlert("AVISO", "Pretende enviar a mensagem ao utilizador?");

		if (role != EnumGenericRoleActionAlerts.Confirm) return;

		if (this.reviewBooking) {

			const submit = {
				observations: this.Form.get('Observations').value || "",
			};

			const response = await this.reviewBooking(submit);

			if (response)
				this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Save);
		}
	}

	/**
	 * 
	 * @param ev 
	 */
	async OnCancelBooking(ev: any) {


		if (this.cancelBooking) {

			const response: boolean = await this.cancelBooking();

			console.log(response);

			if (response)
				this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Save);
		}
	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

	/**
	 * 
	 * @param ev 
	 * @returns 
	 */
	OpenDonation(ev: any) {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data

		const id = this.Form.get('IdArticle').value;


		if (!id) return;

		const url: URL = new URL(window?.location?.href || '');
		const path = url.pathname = EnumAppMenu.DonationsMyListView + "/" + id;

		// console.log(url, path);

		window.open(path, '_blank');

	}

}
