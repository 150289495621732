import { ProjectService } from './../../services/project.service';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
// import { ImageCropperModalComponent } from '@modals/image-cropper/image-cropper.component';

import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'image-cropper-render',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, OnChanges {

	@Input() data;
	@Input() disabled;
	@Input() presentModal: Function = new Function();
	@Output() callback = new EventEmitter<string>();

	public DefaultImage: string;
	imageChangedEvent: any;
	croppedImage: string;

	modal: HTMLElement;

	constructor(
		// private ModalController: ModalController
		private ProjectService: ProjectService
	) { }



	ngOnInit(): void {

		const projectSettings = this.ProjectService.GetSettings();
		
		this.DefaultImage = projectSettings?.Project?.DefaultImage || 'assets/project/default_image.jpg';
	}

	ngOnChanges(e): void {
		console.log(e, this.data);

		// this.DefaultImage = 'assets/project/default_image.jpg';
	}



	async OnPresentModal() {

		const { role, data } = await this.presentModal();

		if (role == EnumGenericRoleActionAlerts.Save) {
			this.data = data;

			if (this.callback) this.callback.emit(this.data);
		}

	}

	OnDeleteImage() {
		this.data = null;
		if (this.callback) this.callback.emit(this.data);
	}
}