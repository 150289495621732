import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumAppMenu } from '@models/AppMenus';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { FormValidators } from '@validators/form.validators';

@Component({
	selector: 'app-booking-delivery',
	templateUrl: './booking-delivery.component.html',
	styleUrls: ['./booking-delivery.component.scss'],
})
export class BookingReadingsModalComponent implements OnInit {

	public Form: FormGroup;
	public Delivery: boolean = false;
	private showToast: Function;
	private showAlert: Function;
	private acceptDonation: Function;

	constructor(private NavParams: NavParams,
		private ModalController: ModalController) {

		this.Delivery = this.NavParams.get('delivery') ?? false;

		const data = this.NavParams.get('data') || null;
		this.showToast = this.NavParams.get('showToast') || null;
		this.showAlert = this.NavParams.get('showAlert') || null;
		this.acceptDonation = this.NavParams.get('acceptDonation') || null;

		this.onCreateFormElements(data);
	}

	ngOnInit() {

	}

	private onCreateFormElements(data?: any) {

		this.Form = new FormGroup({
			IdDonation: new FormControl(data?.Id || null, []),
			IdArticle: new FormControl(data?.IdArticle || null, []),
			IdStatus: new FormControl(null, []),
			User: new FormControl(data?.User?.Name || null, []),
			ArticleName: new FormControl(data?.Article?.Name || '', []),
			Observations: new FormControl(null, this.Delivery ? [Validators.required, Validators.maxLength(1000)] : []),
			CloseArticle: new FormControl(null, []),
			QuantityTaken: new FormControl(data?.QuantityReserved || null, this.Delivery ? [Validators.required, FormValidators.NumberValidator] : []),
			DeliveryDescription: new FormControl(null, this.Delivery ? [] : [Validators.required, Validators.maxLength(1000)]),
			DeliverySchedule: new FormControl(null, []),
		})

		// console.log(this.Form);

	}

	/**
	 * 
	 * @param ev 
	 * @returns 
	 */
	async OnAcceptBooking(ev: any) {

		console.log(this.Form);

		if (!this.Form.dirty) return await this.showToast('Não foram encontradas alterações.');

		this.Form.markAllAsTouched();

		if (this.Form.invalid)
			return await this.showToast('Preencha todos os campos assinalados.');


		const { role } = await this.showAlert("AVISO", this.Delivery ? "Pretende entregar a sua doação?" : "Pretende aceitar o pedido de reserva?");

		if (role != EnumGenericRoleActionAlerts.Confirm) return;

		if (this.acceptDonation) {

			const submit = {

				observations: this.Form.get('Observations').value || "",
				closeArticle: this.Form.get('CloseArticle').value ?? false,
				quantityTaken: this.Form.get('QuantityTaken').value || "",
				deliveryDescription: this.Form.get('DeliveryDescription').value || "",
				deliverySchedule: this.Form.get('DeliverySchedule').value || ""
			};

			const response = await this.acceptDonation(submit);

			if (response)
				this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Save);
		}
	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

	/**
	 * 
	 * @param ev 
	 * @returns 
	 */
	OpenDonation(ev: any) {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data

		const id = this.Form.get('IdArticle').value;


		if (!id) return;

		const url: URL = new URL(window?.location?.href || '');
		const path = url.pathname = EnumAppMenu.DonationsMyListView + "/" + id;

		// console.log(url, path);

		window.open(path, '_blank');

	}

}
