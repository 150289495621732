import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { Component, EventEmitter, OnInit, Output, OnChanges, ElementRef, ViewChild } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { ImageCroppedEvent, LoadedImage, ImageTransform } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectService } from '@services/project.service';

@Component({
	selector: 'modal-image-cropper',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperModalComponent implements OnInit, OnChanges {

	@ViewChild('file') File: ElementRef;

	@Output() callback = new EventEmitter<{ name: string, objectUrl: string }>();

	public DefaultImage: string;

	public CropperReady: boolean;

	public Transform: ImageTransform;

	public CanvasRotation: number;

	Filename: string;

	imageChangedEvent: any;

	croppedImage: any;

	constructor(
		public ModalController: ModalController,
		private ProjectService: ProjectService,
		private sanitizer: DomSanitizer
	) {

		this.CropperReady = false;

		this.CanvasRotation = 0;

		this.Transform = {};

		const projectSettings = this.ProjectService.GetSettings();

		this.DefaultImage = projectSettings?.Project?.DefaultImage || 'assets/project/default_image.jpg';
	}


	ngOnInit(): void {


		if (this.DefaultImage) {
			const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

			if (base64regex.test(this.DefaultImage)) {

				this.CropperReady = true;
			}
		}
	}

	ngOnChanges() {
		this.imageChangedEvent = null;
		this.croppedImage = '';
	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

	fileChangeEvent(event: any): void {

		console.log(event);
		this.imageChangedEvent = event;

		this.Filename = this.File.nativeElement?.files[0].name || null;
		this.OnResetTranforms();
		this.CropperReady = true;
		// console.log(file);

	}

	imageCropped(event: ImageCroppedEvent) {

		console.log(event);

		this.croppedImage = event?.objectUrl;

		// this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
		// event.blob can be used to upload the cropped image

		// this.croppedImage = event?.base64
	}

	imageLoaded(image?: LoadedImage) {
		// show cropper
	}

	cropperReady() {
		// cropper ready

	}

	loadImageFailed() {
		// show message
	}

	async SaveImage() {

		// debugger

		await this.callback.emit({ objectUrl: this.croppedImage, name: this.Filename });

		this.ModalController.dismiss({ objectUrl: this.croppedImage, name: this.Filename }, EnumGenericRoleActionAlerts.Save);
	}

	public OnResetTranforms() {

		this.CanvasRotation = 0;

		this.Transform = {};
	}

	public OnRotateLeft() {
		if (this.croppedImage) {
			this.CanvasRotation -= 45;

			// this.flipAfterRotate();
		}
	}

	public OnRotateRight() {
		if (this.croppedImage) {
			this.CanvasRotation += 45;

			// this.flipAfterRotate();
		}
	}


	public OnFlipHorizontal() {
		if (this.croppedImage) {
			this.Transform = { ...this.Transform, flipH: !this.Transform.flipH };
		}
	}

	public OnFlipVertical() {
		if (this.croppedImage) {
			this.Transform = { ...this.Transform, flipV: !this.Transform.flipV };
		}
	}

	private flipAfterRotate() {
		const flippedH = this.Transform.flipH;
		const flippedV = this.Transform.flipV;

		this.Transform = { ...this.Transform, flipH: flippedV, flipV: flippedH };
	}

	public OnZoomIn() {

		const zoom = (this.Transform.scale || 1) + 1;

		if (this.croppedImage) {
			this.Transform = { ...this.Transform, scale: zoom };

		}
	}

	public OnZoomOut() {

		const zoom = (this.Transform.scale || 1) - 1;

		if (this.croppedImage) {
			this.Transform = { ...this.Transform, scale: zoom };

		}
	}


}
