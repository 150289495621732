import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'side-menu',
    template: `
			<ion-content>
				<ion-list id="sidemenu-list">

					<side-menu-header></side-menu-header>

					<side-menu-content></side-menu-content>

				</ion-list>

				<!-- <ion-list id="sidemenu-options">

					<ion-list-header>Outras Acções</ion-list-header>
				
					<ion-item *ngFor="let label of labels" lines="none" button>
						<ion-icon slot="start" name="lock-closed-outline"></ion-icon>
						<ion-label>{{ label }}</ion-label>
					</ion-item>

				</ion-list> -->

			</ion-content>`
})
export class SidemMenuComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}
