import { GoogleMapsService } from '@services/google-maps.service';
import { Component, Input, OnInit, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapKmlLayer, MapMarker } from '@angular/google-maps';

@Component({
	selector: 'google-map-container',
	templateUrl: './google-map-container.component.html',
	styleUrls: ['./google-map-container.component.scss'],
})
export class GoogleMapContainerComponent implements OnInit, OnChanges {

	@Input('class') Class: 'small' | 'medium' | 'large' = 'medium';

	@Input('mapId') MapId: string = "";

	@Input('options') OptionsInit: google.maps.MapOptions;

	@Input('pin') MarkerLocation: { Latitude: string, Longitude: string } = null;

	@Input('polygonUrl') PolygonUrl: any = null;

	@Input('onDrag') onDrag: Function;

	@ViewChild(GoogleMap, { static: false }) Map!: GoogleMap;

	// @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

	// infoContent: string = '';

	public Markers: any[] = [] as any;

	public Options: google.maps.MapOptions | any;

	@ViewChild('kmlLayer') KmlLayerContainer: MapKmlLayer;

	private mapStarted: boolean = false;


	constructor(private GoogleMapsService: GoogleMapsService) {


	}

	ngOnInit() {

		console.log("google map container -> ", this.MapId, this.Map, this.Markers);

		// this.loadMap();
	}

	ngOnChanges(ev) {

		this.loadMap();

	}

	private parseMarker(marker, index: number = 0) {

		return {
			title: 'localização',
			options: {
				draggable: this.OptionsInit.gestureHandling != 'none',
				data: {
					index: index,
					original: new google.maps.LatLng(marker?.Latitude || '', marker?.Longitude || '').toJSON()
				}
			},
			position: new google.maps.LatLng(marker?.Latitude || '', marker?.Longitude || '').toJSON()
		};
	}

	/**
	 * 
	 */
	private loadMap() {

		const mapStyle = this.GoogleMapsService.GetMapStyle();

		this.Options = this.OptionsInit ? JSON.parse(JSON.stringify(this.OptionsInit)) : {};
		this.Options['styles'] = mapStyle;

		this.Markers = [this.parseMarker(this.MarkerLocation, 0)];

		if (this.PolygonUrl && !this.mapStarted) {

			const center = this.KmlLayerContainer.kmlLayer.getMap().getCenter().toJSON();
			console.log(center);
			this.Options['center'] = center;
		}

		if (this.Markers && this.Markers.length > 0 && this.Markers[0].position.lat) {
			this.Options['center'] = { lat: this.Markers[0].position.lat, lng: this.Markers[0].position.lng }
		}

		// obter localização atual do utilizador
		// navigator.geolocation.getCurrentPosition((position) => {

		// 	console.log(position);

		// 	// if (!this.Options)
		// 	// 	this.Options.center = {
		// 	// 		lat: position.coords.latitude,
		// 	// 		lng: position.coords.longitude,
		// 	// 	}
		// })

		// this.Map = this.GoogleMapsService.GetMap();

		// this.mapStyle = this.GoogleMapsService.GetMapStyle();

		// this.MapContainer?.mapTypes.set('styled_map', this.mapStyle);
	}

	OnOpenInfo(marker: MapMarker, content: string) {
		console.log(marker, content);

		// this.infoContent = content;
		// this.info.open(marker)
	}

	/**
	 * 
	 * @param ev 
	 * @param marker 
	 */
	OnDrag(ev: any, marker) {

		// update position original marker

		this.onDrag({ Latitude: ev.latLng.lat(), Longitude: ev.latLng.lng() })


	}
}
