import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperModalComponent } from './image-cropper/image-cropper.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MenuSelectModalComponent } from './menu-select/menu-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectUserProfileModalComponent } from './select-user-profile/select-user-profile.component';
import { SelectFileUploadModalComponent } from './select-file-upload/select-file-upload.component';
import { ComponentsModule } from '@components/components.module';
import { CameraModalComponent } from './camera/camera.component';
import { PresentImageModalComponent } from './present-image/present-image.component';
import { ItemSelectModalComponent } from './item-select/item-select.component';
import { BookingReadingsModalComponent } from './booking-delivery/booking-delivery.component';
import { BookingReviewModalComponent } from './booking-review/booking-review.component';

const components = [
	ImageCropperModalComponent,
	// MenuSelectModalComponent,
	ItemSelectModalComponent,
	SelectUserProfileModalComponent,
	SelectFileUploadModalComponent,
	CameraModalComponent,
	PresentImageModalComponent,
	BookingReadingsModalComponent,
	BookingReviewModalComponent
];

@NgModule({
	declarations: [components],
	exports: [components],
	imports: [
		CommonModule,
		IonicModule,
		ImageCropperModule,
		ReactiveFormsModule,
		ComponentsModule
	]
})
export class ModalsModule { }
