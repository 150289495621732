import { StorageService } from '@services/storage.service';
import { Subscription } from 'rxjs';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { WebService } from './../../services/web.service';
import { AuthService } from '@services/auth.service';
import { NavController, NavParams, PopoverController, AlertController } from '@ionic/angular';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SessionService } from '@services/session.service';

@Component({
	selector: 'header-page',
	templateUrl: './header-page.component.html',
	styleUrls: ['./header-page.component.scss'],
})
export class HeaderPageComponent implements OnInit {
	/**
	 * Enabled component of season|edition
	 */
	@Input('isEnabled') IsEnabled: boolean;

	@Input('backPath') BackPath: string;

	@Input('onGoBack') onGoBack: Function = null;

	/**
	 *  Returns selected season id to allow reload pages with the correct season|edition
	 */
	@Output() callback?: EventEmitter<any> = new EventEmitter<any>();

	// public Logo: String = "assets/project/project_logo.svg";

	public User: any;

	private sub: Subscription;

	public Entity: { Id: string, Name: string } = null;


	constructor(private NavController: NavController,
		private PopoverController: PopoverController,
		private AuthService: AuthService,
		private SessionService: SessionService) { }

	ngOnInit() {

		this.IsEnabled = this.IsEnabled ?? true;
		this.sub = this.SessionService.Session.subscribe(session => {
			this.User = session?.UserData;
			this.Entity = session?.Entity || null;
			// console.log(this.User);
		})

	}

	ngOnDestroy(): void {
		if (this.sub)
			this.sub.unsubscribe();
	}

	OnChangeEdition(edition: any) {

		if (this.callback)
			this.callback.emit(edition);
	}

	public OnNavigateNotifications(ev?: any) {
		this.NavController.navigateForward('notifications');
	}

	public OnGoBack() {
		if (this.onGoBack)
			this.onGoBack();
	}

	private OnGoAccount() {
		this.NavController.navigateForward('account');
	}

	private OnGoSettings() {
		this.NavController.navigateForward('settings');
	}

	private async OnLogout() {
		await this.AuthService.Logout();
		this.NavController.navigateRoot('login');
	}

	async OnShowDefinitions(ev: any) {
		const popover = await this.PopoverController.create({
			component: PopoverHeaderComponent,
			componentProps: {
				onAccount: () => this.OnGoAccount(),
				onSettings: () => this.OnGoSettings(),
				onLogout: () => this.OnLogout()
			},
			// cssClass: 'my-custom-class',
			event: ev,
			translucent: true
		});
		await popover.present();

		const { role } = await popover.onDidDismiss();
		// console.log('onDidDismiss resolved with role', role);
	}
}


@Component({
	selector: 'popover-menu',
	template: `
		<ion-content class="ion-padding">
			<ion-item button lines="none" detail="false" class="ion-no-margin" (click)="OnGoAccount()">
				<ion-icon iconMode slot="start" name="person-circle-outline"></ion-icon>
				<ion-label>A minha conta</ion-label>
			</ion-item>
			<ion-item button lines="none" detail="false" class="ion-no-margin" (click)="OnGoSettings()">
				<ion-icon iconMode slot="start" name="settings-outline"></ion-icon>
				<ion-label>Definições</ion-label>
			</ion-item>
			<ion-item button lines="none" detail="false" class="ion-no-margin" (click)="OnGoLogout()">
				<ion-icon iconMode slot="start" name="log-out-outline"></ion-icon>
				<ion-label>Terminar sessão</ion-label>
			</ion-item>
		</ion-content>
	`
})
export class PopoverHeaderComponent implements OnInit {

	private onGoAccount: Function;
	private onGoSettings: Function;
	private onGoLogout: Function;

	constructor(private NavParams: NavParams, private PopoverController: PopoverController, private AlertController: AlertController) {

		this.onGoAccount = this.NavParams.get('onAccount');
		this.onGoSettings = this.NavParams.get('onSettings');
		this.onGoLogout = this.NavParams.get('onLogout');
	}

	ngOnInit(): void {

	}

	OnGoAccount() {
		if (this.onGoAccount) this.onGoAccount();
		this.PopoverController.dismiss();
	}

	OnGoSettings() {
		if (this.onGoSettings) this.onGoSettings();
		this.PopoverController.dismiss();
	}

	async OnGoLogout() {

		const role = await this.showAlert();

		if (role == 'cancel') return;

		if (this.onGoLogout) this.onGoLogout();

		this.PopoverController.dismiss();
	}

	/**
	 * 
	 * @returns role
	 */
	private async showAlert() {

		const alert = await this.AlertController.create({
			cssClass: 'default',
			header: 'Aviso',
			subHeader: '',
			message: 'Pretende terminar a sua sessão?',
			backdropDismiss: false,
			buttons: [
				{
					text: 'cancelar',
					role: EnumGenericRoleActionAlerts.Cancel
				},
				{
					text: 'Continuar',
					role: EnumGenericRoleActionAlerts.Confirm
				}
			]
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();

		return role;

	}

}
