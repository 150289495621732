import { ToastController } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { GoogleMapsService } from '@services/google-maps.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { environment } from '@environments/environment';
import { AsyncUtils } from '@utils/AsyncUtils';

export enum ENUM_MAP_ADDRESS_TYPE {
	POINT = "1",
	POLYGON = "2",
	EXTERNAL = "3"

}

@Component({
	selector: 'address-google-map',
	templateUrl: './address-google-map.component.html',
	styleUrls: ['./address-google-map.component.scss'],
})
export class AddressGoogleMapComponent implements OnInit {

	@Input('form') FormGroup: FormGroup | any;
	@Input('mapOptions') MapOptions: any;
	@Input('mapId') MapId: string;
	@Input('label') Title: string;
	@Input('polygonUrl') PolygonUrl: string;

	@Input('size') Size: 'small' | 'medium' | 'large' = 'medium';

	@Input('typeOptions') TypeOptions: string[] = [ENUM_MAP_ADDRESS_TYPE.POINT];

	@Input('required') Required: boolean = false;

	private marker: { original: any, new: any } = { original: null, new: null };

	constructor(private GoogleMapsService: GoogleMapsService,
		private ToastController: ToastController) { }

	ngOnInit() {

	}

	/**
	 * 
	 * @param formgroup 
	 * @returns 
	 */
	public async OnSearchStreet(formgroup: FormGroup = this.FormGroup) {

		const address = ((formgroup.get('Address').value || '') + " " +
			(formgroup.get('ZipCode').value || '') + " " +
			(formgroup.get('District').value || '') + " " +
			(formgroup.get('County').value || '') + " " +
			(formgroup.get('Locality').value || '')
		).trim().replace(/ /g, '+');

		if (!address || !formgroup.get('ZipCode').value) return this.FormGroup.markAllAsTouched();

		const response: any[] = await this.GoogleMapsService.GetCoordenatesFromStreetName(`${address}`);


		if (!response || response.length == 0) return this.showToast('Sem resultados para a morada indicada!');

		const newAddress = response[0];

		const coords = newAddress?.geometry?.location || { lat: null, lng: null };

		const district = newAddress.address_components?.find(it => it?.types?.includes('administrative_area_level_1'));
		const county = newAddress.address_components?.find(it => it?.types?.includes('administrative_area_level_2'));
		const locality = newAddress.address_components?.find(it => it?.types?.includes('administrative_area_level_3'));
		// console.log(formgroup, coords);

		formgroup.get('Coordinates').get('Latitude').setValue(coords.lat);
		formgroup.get('Coordinates').get('Longitude').setValue(coords.lng);
		formgroup.get('Coordinates').markAsDirty();
		formgroup.get('Coordinates').markAsTouched();

		formgroup.get('Address').setValue(newAddress?.formatted_address);
		formgroup.get('Address').markAsDirty();
		formgroup.get('Address').markAsTouched();

		formgroup.get('District').setValue(district?.long_name?.replace('District', '')?.trim());
		formgroup.get('District').markAsDirty();
		formgroup.get('District').markAsTouched();

		formgroup.get('County').setValue(county?.long_name);
		formgroup.get('County').markAsDirty();
		formgroup.get('County').markAsTouched();

		formgroup.get('Locality').setValue(locality?.long_name);
		formgroup.get('Locality').markAsDirty();
		formgroup.get('Locality').markAsTouched();


	}

	/**
	 * 
	 * @param formgroup 
	 */
	public OnEnabledMarker(formgroup: FormGroup = this.FormGroup) {


		if (!formgroup.get('Coordinates').get('Latitude').value || !formgroup.get('Coordinates').get('Longitude').value)
			return this.FormGroup.markAllAsTouched();

		const tmp = Object.assign({}, this.MapOptions);

		tmp.gestureHandling = tmp.gestureHandling == 'auto' ? 'none' : 'auto';


		if (tmp.gestureHandling == 'none') {

			// formgroup.disable();
			formgroup.markAllAsTouched();
			formgroup.markAsDirty();

			if (this.marker) {

				formgroup.get('Coordinates').get('Latitude').setValue(this.marker.new?.Latitude);
				formgroup.get('Coordinates').get('Longitude').setValue(this.marker.new?.Longitude);


			}
		}
		else {
			// formgroup.enable();
			this.marker.original = formgroup.get('Coordinates').value;

			// formgroup.get('District').reset();
			// formgroup.get('County').reset();
			// formgroup.get('ZipCode').reset();
		}

		this.MapOptions = tmp;
	}

	/**
	 * 
	 * @param formgroup 
	 */
	public async OnCancelRepositionMarker(formgroup: FormGroup = this.FormGroup) {

		const tmp = Object.assign({}, this.MapOptions);

		tmp.gestureHandling = 'none';

		this.MapOptions = tmp;

		formgroup.get('Coordinates').get('Latitude').setValue(this.marker.original?.Latitude);
		formgroup.get('Coordinates').get('Longitude').setValue(this.marker.original?.Longitude);

		await AsyncUtils.Sleep(100);
	}

	/**
	 * 
	 * @param marker 
	 */
	public OnDragMarker = async (marker) => {

		this.marker.new = marker;

		// const response = await this.GoogleMapsService.GetStreetNameFromCoordenates(marker?.Latitude, marker?.Longitude);

		// if (response?.length > 0) {

		// 	const address = response[0];

		// 	const district = address.address_components?.find(it => it?.types?.includes('administrative_area_level_1'));
		// 	const county = address.address_components?.find(it => it?.types?.includes('administrative_area_level_2'));
		// 	const locality = address.address_components?.find(it => it?.types?.includes('administrative_area_level_3'));
		// 	const postalcode = address.address_components?.find(it => it?.types?.includes('postal_code'));

		// 	console.log(locality);

		// 	if (district?.long_name) this.FormGroup.get('District').patchValue(district?.long_name);
		// 	if (county?.long_name) this.FormGroup.get('County').patchValue(county?.long_name);
		// 	if (postalcode?.long_name) this.FormGroup.get('ZipCode').patchValue(postalcode?.long_name);
		// 	if (address?.formatted_address) this.FormGroup.get('Address').patchValue(address?.formatted_address);
		// 	if (locality?.long_name) this.FormGroup.get('Locality').patchValue(locality?.long_name);
		// }

	}

	/**
	 * 
	 * @param message 
	 * @param duration 
	 * @returns 
	 */
	private async showToast(message: string, duration: number = 3000) {
		const toast = await this.ToastController.create(
			{
				duration: duration,
				message: message,
				cssClass: "default-toast",
				position: "top",
				mode: "ios",
				buttons:
					[
						{
							icon: 'close',
							role: EnumGenericRoleActionAlerts.Cancel,
							handler: () => { if (toast) toast.dismiss(); }
						}
					]
			});

		await toast.present();

		return toast;
	}

}
