<ion-app>
	<ion-split-pane contentId="main-content">
		
		<ion-menu class="sidemenu" contentId="main-content" type="overlay">
			<side-menu style="height: 100%;"></side-menu>
		</ion-menu>

		<ion-router-outlet id="main-content"></ion-router-outlet>

	</ion-split-pane>
</ion-app>