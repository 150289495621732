import { IEditionBaseModel } from './../../../models/Edition';
import { EnumAPIStatus } from './../../../api/AbstractAPI';
import { EditionService } from '@services/edition.service';
import { NavController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { EnumAppMenu } from '@models/AppMenus';

@Component({
	selector: 'configurations-edition',
	templateUrl: './edition.component.html',
	styleUrls: ['./edition.component.scss'],
})
export class EditionConfigurationsComponent implements OnInit {

	@Input() Data: any[];

	@Input('delete') Delete: Function;

	SeletedEdition: IEditionBaseModel;

	constructor(private NavController: NavController,
		private EditionService: EditionService) { }

	ngOnInit() { 

		this.SeletedEdition = this.EditionService.GetEdition();
	}

	OnChangePage(e) {

	}

	OnEdit(item: any) {

		this.NavController.navigateForward([EnumAppMenu.ConfigurationsEditionEdit, item?.Id]);
	}

	async OnDelete(item: any) {

		if (!this.Delete) return;

		const response = await this.Delete(item);

	}

}
