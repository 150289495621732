<ion-card class="ion-no-padding" (click)="OnPresentImage($event)">

	<ion-button class="main" *ngIf="EnableMain && (!IsEnabled && IsMain)" shape="round" [fill]="'clear'" size="small"
		color="primary">
		<ion-icon slot="icon-only" [name]="'star'"></ion-icon>
	</ion-button>

	<img [alt]="Name" [src]="Url" />


	<ion-buttons *ngIf="IsEnabled">

		<ion-button *ngIf="EnableMain" title="Definir imagem como principal" shape="round"
			[color]="IsMain ? 'primary': 'light'" [fill]="'solid'" size="small" (click)="OnSetMain($event)">
			<ion-icon slot="icon-only" [name]="'star'"></ion-icon>
		</ion-button>

		<ion-button title="Remover imagem" shape="round" fill="solid" size="small" color="danger"
			(click)="OnDelete($event)">
			<ion-icon slot="icon-only" name="trash-outline"></ion-icon>
		</ion-button>

	</ion-buttons>

</ion-card>