import { IProjectSettings } from '@models/ProjectSettings';
import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HTMLUtils } from '@utils/HTMLUtils';

declare let gtag: Function;
export interface SEOPageInfo {
	Title?: string,
	Description?: string,
	Image?: string
}

//tslint:disable-next-line:deprecation
@Injectable(
	{
		providedIn: 'root'
	})
export class SEOService {
	//private navigationStart$:Observable<any>;

	private renderedStatic: string[];

	constructor(private Title: Title, private Meta: Meta, private Router: Router) {
		this.renderedStatic = [];
	}

	/**
	 * Efectua carregamento da API Google Analitycs caso o tracking ID seja válido.
	 * @param trackingID string
	 */
	SetupGoogleAnalytics(settings: IProjectSettings) {
		if (!settings?.SEO?.GoogleAnalytics) return;

		let gascript = document.createElement('script');

		gascript.setAttribute('async', 'true');
		gascript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${settings?.SEO?.GoogleAnalytics}`);

		let gascript2 = document.createElement('script');
		gascript2.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){ dataLayer.push(arguments); } gtag(\'js\', new Date()); gtag(\'config\', \'${settings?.SEO?.GoogleAnalytics}\');`;

		document.documentElement.firstChild.appendChild(gascript);
		document.documentElement.firstChild.appendChild(gascript2);

		//TRACKING CUSTOM PAGES GoogleAnalytics
		this.Router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
			try { gtag('config', settings?.SEO?.GoogleAnalytics, { 'page_path': e.urlAfterRedirects }); }
			catch (error) { console.log("No report script running.") }
		});
	}

	/**
	 * Actualiza metadata e og da index da pagina com conjunto de informações do projecto.
	 * @param settings IProjectSettings
	 */
	UpdateProjectMetadata(settings: IProjectSettings) {
		//SETUP PAGE TITLE
		this.Title.setTitle(settings.Project?.Title);

		//GOOGLE VERIRICATION CODE
		this.Meta.updateTag({ name: 'google-site-verification', content: settings?.SEO?.GoogleSiteVerification });

		//SETUP HTML METAS
		this.Meta.updateTag({ name: 'description', content: settings.Project?.Description });
		this.Meta.updateTag({ name: 'keywords', content: settings.Project?.Keywords });

		//SETUP METAS OG FACEBOOK/REDES SOCIAIS
		this.Meta.updateTag({ property: 'og:description', content: settings.Project.Description });
		this.Meta.updateTag({ property: 'og:title', content: settings.Project.Title });
		this.Meta.updateTag({ property: 'og:site_name', content: settings.Project.Title });
		this.Meta.updateTag({ property: 'og:image', content: `${window.location.origin}/${settings.Project?.ShareImage}` });
		this.Meta.updateTag({ property: 'og:image:alt', content: `Imagem ${settings.Project.Title}` });
		this.Meta.updateTag({ property: 'og:image:type', content: 'image/png' });
		this.Meta.updateTag({ property: 'og:image:width', content: '1200' });
		this.Meta.updateTag({ property: 'og:image:height', content: '630' });
		this.Meta.updateTag({ property: 'og:type', content: 'website' });
		this.Meta.updateTag({ property: 'og:url', content: window.location.origin });
		this.Meta.updateTag({ property: 'og:locale', content: 'pt_PT' });
	}

	/**
	 * Actualiza dados da página.
	 * @param seo SEOPageInfo
	 */
	UpdatePageMetadata(seo: SEOPageInfo = {}) {
		if (seo?.Title) {
			this.Title.setTitle(seo.Title);

			this.Meta.updateTag({ property: 'og:title', content: seo.Title });
		}

		if (seo?.Description) {
			this.Meta.updateTag({ name: 'description', content: seo.Description });
			this.Meta.updateTag({ property: 'og:description', content: seo.Description });
		}

		if (seo?.Image) {
			this.Meta.updateTag({ property: 'og:image', content: seo.Image });
		}

		this.Meta.updateTag({ property: 'og:url', content: window.location.origin + window.location.pathname });
	}

	/**
	 * Efectua render de pagina estática do endereço actual.
	 * Guarda em cache de execução quais as paginas já renderizadas.
	 * Caso já tenha renderizado não invoca novamente a renderização da página.
	 * @returns 
	 */
	public RenderStaticPage() {
		if (this.renderedStatic.includes(window.location.pathname)) return;

		try {
			if (window.location.host.includes("localhost") || window.location.host.includes("demo.smartcouncil.boldapps.pt")) return;

			const path = window.location.pathname;

			let html = document.documentElement.innerHTML.toString();

			html = HTMLUtils.RemoveHTMLLinkStyleSheetTags(html);
			html = HTMLUtils.RemoveHTMLScriptTags(html);
			html = HTMLUtils.RemoveHTMLStyleTags(html);
			html = HTMLUtils.RemoveHTMLComments(html);
			html = HTMLUtils.TrimHTML(html);

			html = '<!DOCTYPE html><html lang="pt">' + html + '</html>';

			const formData = new FormData();
			formData.append("url", path);
			formData.append("pageHtml", html);

			fetch("/seo.php", { method: "POST", body: formData })
				.then(result => { this.renderedStatic.push(path); })
				.catch(error => { })
		}
		catch (error) { }
	}

	// RenderStatic()
	// {
	//     this.navigationStart$ = this.Router.events.pipe(
	//     filter(e => e instanceof NavigationStart), 
	//     startWith(null), //Start with something, because the app doesn't fire this on appload, only on subsequent route changes
	//     tap(e => 
	//     {
	//         //Once NavigationStart has fired, start checking regularly until there are no more pending macrotasks in the zone
	//         //Have to run this outside of the zone, because the call to `interval` is itself a macrotask. 
	//         //Running that `interval` inside the zone will prevent the macrotasks from ever reaching zero. Running this outside
	//         //of Angular will not track this `interval` as a macrotask. IMPORTANT!!
	//         this.NgZone.runOutsideAngular(() => 
	//         {
	//             //interval(10) Check very regularly to see if the pending macrotasks have all cleared
	//             //startWith(0) So that we don't initially wait
	//             //takeUntil(this.navigationStart$) To prevent a memory leak on two closely times route changes, take until the next nav start
	//             //map(() => !this.NgZone.hasPendingMacrotasks) Turn the interval number into the current state of the zone
	//             //filter(stateStable => stateStable === true) Don't emit until the zone state actually flips from `false` to `true`
	//             //take(1) Complete the observable after it emits the first result
	//             const interval$ = interval(10).pipe
	//             (
	//                 startWith(0),
	//                 takeUntil(this.navigationStart$),
	//                 map(() => !this.NgZone.hasPendingMacrotasks),
	//                 distinctUntilChanged(), 
	//                 filter(stateStable => stateStable === true), 
	//                 take(1),
	//                 tap(stateStable => 
	//                 {
	//                     console.log(stateStable);

	//                     if (window.location.host.includes("localhost") || window.location.host.includes("smartcard.boldapps.pt")) return;

	//                     let html = document.documentElement.innerHTML.toString();

	//                     html = HTMLUtils.RemoveHTMLLinkStyleSheetTags(html);
	//                     html = HTMLUtils.RemoveHTMLScriptTags(html);
	//                     html = HTMLUtils.RemoveHTMLStyleTags(html);
	//                     html = HTMLUtils.RemoveHTMLComments(html);
	//                     html = HTMLUtils.TrimHTML(html);

	//                     html = '<!DOCTYPE html><html lang="pt">' + html + '</html>';

	//                     const formData = new FormData();
	//                     formData.append("url", window.location.pathname);
	//                     formData.append("pageHtml", html);

	//                     fetch("/ajax.php", { method: "POST", body: formData })
	//                     .then(function(result)  { console.log("Result AJAX Call: " + result) })
	//                     .catch(function(error)  { console.log("Error AJAX Call: " + error) })
	//                 })
	//             ).subscribe();
	//         });
	//     }));

	//     //this.navigationStart$.subscribe();
	// }
}