import { Component, Input, OnInit, OnChanges } from '@angular/core';

import { ColorsUtils } from '@utils/ColorsUtils';
import { StringUtils } from '@utils/StringUtils';

@Component(
{
	selector: 'user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnChanges 
{
	@Input() name: string;

	public Initials: string;

	public Color: string;

	constructor() { }

	ngOnInit() 
	{		
		// console.log(this.name);
		
		this.Initials = StringUtils.GetInitials(this.name);

		this.Color = ColorsUtils.HSLColorFromString(this.name);
	}

	ngOnChanges()
	{

		this.Initials = StringUtils.GetInitials(this.name);

		this.Color = ColorsUtils.HSLColorFromString(this.name);
	}

}
