import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'loading-table',
	templateUrl: './loading-table.component.html',
	styleUrls: ['./loading-table.component.scss'],
})
export class LoadingTableComponent implements OnInit {

	@Input() toolbar: number = 2;

	constructor() { }

	ngOnInit() { }

}
