import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionService } from '@services/session.service';
import { Observable } from 'rxjs';

export interface isDeactivatable {
	onCanLeavePage: (
		nextUrl?: string
	) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
	providedIn: 'root'
})

export class CanLeavePageGuard  {

	constructor(
		private SessionService: SessionService){}

	canDeactivate(
		component: isDeactivatable,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return component.onCanLeavePage && this.SessionService.HasSession() ? component.onCanLeavePage(nextState.url) : true;
	}

}
