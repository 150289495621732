export interface ICoordinates {
    Latitude: string;
    Longitude: string;
}

export class Coordinates implements ICoordinates {
    public Latitude: string;
    public Longitude: string;

    constructor(data: Partial<ICoordinates> = {}) {
        this.Latitude = data.Latitude || "";
        this.Longitude = data.Longitude || "";
    }
}

export interface ILocationModel {
    Address?: string;
    ZipCode?: string;
    Locality?: string;
    Street?: string;
    District?: string;
    County?: string;
    Coordinates: ICoordinates;
    Id?: number;
    Name?: string;
    RegistDate?: string;
    RegistUser?: number;
}

export class LocationModel implements ILocationModel {
    Address?: string;
    ZipCode?: string;
    Locality?: string;
    Street?: string;
    District?: string;
    County?: string;
    Coordinates: ICoordinates;
    Id?: number;
    Name?: string;
    RegistDate?: string;
    RegistUser?: number;

    constructor(data: Partial<LocationModel> = {}) {

        this.Address = data?.Address || null;
        this.ZipCode = data?.ZipCode || null;
        this.Locality = data?.Locality || null;
        this.Street = data?.Street || null;
        this.District = data?.District || null;
        this.County = data?.County || null;
        this.Name = data?.Name || null;
        this.Coordinates = data?.Coordinates || null;
        this.Id = data?.Id || null;

    }
}

export interface IContactModel {
    Phone?: string,
    MobilePhone?: string,
    Email?: string,
    LabelPhoneList?: string,
    Id: number,
    RegistDate?: string,
    RegistUser?: number
}

export class ContactModel implements IContactModel {
    Phone?: string;
    MobilePhone?: string;
    Email?: string;
    LabelPhoneList?: string;
    Id: number;
    RegistDate?: string;
    RegistUser?: number;

    constructor(data: Partial<IContactModel> = {}) {

        this.Id = data?.Id || null;
        this.Phone = data?.Phone || null;
        this.MobilePhone = data?.MobilePhone || null;
        this.Email = data?.Email || null;
        this.LabelPhoneList = data?.LabelPhoneList || null;
        this.RegistDate = data?.RegistDate || null;
        this.RegistUser = data?.RegistUser || null;

    }
}

export interface IKeyValue {
    Key: string,
    Value: string
}

export interface INameAndId {
    Id: string;
    Name: string;
}

export enum EnumPlacesType {
    Continent = '1',
    Country = '2',
    District = '3',
    County = '4',
    Parish = '5'
}

export enum EnumDownloadFormatType {
    Unknown = 0,
    Excel = 1,
    PDF = 2
}


// export enum EnumDonationsStatus {
//     Pending = 1,
//     Accepted = 2,
//     Rejected = 3,
//     PendingResponsibleApproval = 4,
//     Finished = 5,
//     InProgress = 6,
//     Canceled = 7
// }

export enum EnumAttachmentsTypes {
    // Default
    NotDefined = 1,
    Image = 2,
    // Specific
    PermanentCertificateRegistration = 101,
    BeginningOfActivityDeclaration = 102,
    NoDebtToSocialSecurityDeclaration = 103,
    NoDebtToTaxAuthoritySecurityDeclaration = 104,
    SocialSecurityDeclaration = 105,
    BankAccountNumberProof = 106
}

export enum EnumPageRow {
    DEFAULT = 25,
    R25 = 25,
    R50 = 50,
    R100 = 100,
}

export enum EnumDefaultStatus {
    Pending = 1,
    Accepted = 2,
    Rejected = 3,
    Finished = 4,
    Blocked = 6,
    Canceled = 7,
    //NEW STATUS
    RejectedDeliveryInformation = 103,
    PendingDeliveryConfirmation = 102
}


export interface ICommonStatusModel {
    Id: number;
    Name: string;
    Color?: string;
    Icon?: string;
    IsCompleted?: boolean;
    IsSuccess?: boolean;
};

export class CommonStatusModel implements ICommonStatusModel {
    Id: number;
    Name: string;

    Color?: string;
    Icon?: string;

    IsCompleted?: boolean;
    IsSuccess?: boolean;

    constructor(data: Partial<ICommonStatusModel> = {}) {

        this.Id = data?.Id ? Number(data?.Id) : null;
        this.Name = data?.Name || null;
        this.IsCompleted = data?.IsCompleted || null;
        this.IsSuccess = data?.IsSuccess || null;

        switch (this.Id) {

            case EnumDefaultStatus.Pending:
            case EnumDefaultStatus.PendingDeliveryConfirmation:
                this.Color = 'warning';
                this.Icon = "alert-circle-outline";
                break;

            case EnumDefaultStatus.Accepted:
                this.Color = 'success';
                this.Icon = 'checkmark-outline';
                break;

            case EnumDefaultStatus.Finished:
                this.Color = 'success';
                this.Icon = 'checkmark-done-outline';
                break;

            case EnumDefaultStatus.Rejected:
                this.Color = 'danger';
                this.Icon = 'ban-outline';
                break;

            case EnumDefaultStatus.Canceled:
                this.Color = 'danger';
                this.Icon = 'close-outline';
                break;

            case EnumDefaultStatus.RejectedDeliveryInformation:
                this.Color = 'danger';
                this.Icon = "sad-outline";
                break;

            case EnumDefaultStatus.Blocked:
                this.Color = 'tertiary';
                this.Icon = "lock-closed-outline";
                break;

            default:
                this.Color = 'warning';
                this.Icon = "warning-outline";
                break;
        }
    }
}

export interface ICommonHistoricStatusModel {
    Id: string;
    IdDonor?: string;
    IdStatus: number;
    CreateDate?: string;
    Observations: string;
    RegistUser?: { Date: string, Id: string, Role: string, Username: string };
    Status: ICommonStatusModel;
}
export class CommonHistoricStatusModel implements ICommonHistoricStatusModel {
    Id: string;
    IdStatus: number;
    CreateDate?: string;
    Observations: string;
    RegistUser?: { Date: string, Id: string, Role: string, Username: string };
    Status: ICommonStatusModel;

    constructor(data: Partial<ICommonHistoricStatusModel> = {}) {
        this.Id = data?.Id || null;
        this.IdStatus = data?.IdStatus || null;
        this.CreateDate = data?.CreateDate || null;
        this.Observations = data?.Observations || null;
        this.RegistUser = data?.RegistUser || null;
        this.Status = new CommonStatusModel(data?.Status);
    }
}